import {
  DisponibilitéDVActions,
  CREATE_NEW_DISPONIBILITÉDV,
  DELETE_DISPONIBILITÉDV,
  GET_DISPONIBILITÉDV_LIST,
  UPDATE_DISPONIBILITÉDV_DETAIL,
} from '../../types/actions/DisponibilitéDV.actions';
import {DisponibilitéDVObj} from '../../types/models/secretariat/DisponibilitéDV';

const initialState: {
  objetList: DisponibilitéDVObj[];
  totalObjets: number;
  selectedObjet: DisponibilitéDVObj | null;
} = {
  objetList: [],
  totalObjets: 0,
  selectedObjet: null,
};

const DisponibilitéDVApp = (state = initialState, action: DisponibilitéDVActions) => {
  switch (action.type) {
    case GET_DISPONIBILITÉDV_LIST:
      return {
        ...state,
        objetList: action.payload.list,
        totalObjets: action.payload.total,
      };

    case CREATE_NEW_DISPONIBILITÉDV:
      return {
        ...state,
        objetList: [action.payload, ...state.objetList],
        totalObjets: state.totalObjets + 1,
      };

    case DELETE_DISPONIBILITÉDV: {
      return {
        ...state,
        objetList: action.payload.list,
        totalObjets: action.payload.total,
      };
    }

    case UPDATE_DISPONIBILITÉDV_DETAIL:
      return {
        ...state,
        selectedObjet: action.payload,
        objetList: state.objetList.map(objet =>
          objet.id === action.payload.id ? action.payload : objet,
        ),
      };

    default:
      return state;
  }
};
export default DisponibilitéDVApp;
