import {
  DisponibilitéRVActions,
  CREATE_NEW_DISPONIBILITÉRV,
  DELETE_DISPONIBILITÉRV,
  GET_DISPONIBILITÉRV_LIST,
  UPDATE_DISPONIBILITÉRV_DETAIL,
} from '../../types/actions/DisponibilitéRV.actions';
import {DisponibilitéRVObj} from '../../types/models/secretariat/DisponibilitéRV';

const initialState: {
  objetList: DisponibilitéRVObj[];
  totalObjets: number;
  selectedObjet: DisponibilitéRVObj | null;
} = {
  objetList: [],
  totalObjets: 0,
  selectedObjet: null,
};

const DisponibilitéRVApp = (state = initialState, action: DisponibilitéRVActions) => {
  switch (action.type) {
    case GET_DISPONIBILITÉRV_LIST:
      return {
        ...state,
        objetList: action.payload.list,
        totalObjets: action.payload.total,
      };

    case CREATE_NEW_DISPONIBILITÉRV:
      return {
        ...state,
        objetList: [action.payload, ...state.objetList],
        totalObjets: state.totalObjets + 1,
      };

    case DELETE_DISPONIBILITÉRV: {
      return {
        ...state,
        objetList: action.payload.list,
        totalObjets: action.payload.total,
      };
    }

    case UPDATE_DISPONIBILITÉRV_DETAIL:
      return {
        ...state,
        selectedObjet: action.payload,
        objetList: state.objetList.map(objet =>
          objet.id === action.payload.id ? action.payload : objet,
        ),
      };

    default:
      return state;
  }
};
export default DisponibilitéRVApp;
