import React, {useContext} from 'react';
import { createLocalStorageStateHook } from 'shared/use-local-storage-state';
import {Box} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import AppContext from '../../../@crema/utility/AppContext';
import {useAuthUser, cannotEditAdmin} from '../../../@crema/utility/AppHooks';
import {ThemeMode} from '../../constants/AppEnums';
import Hidden from '@material-ui/core/Hidden';
import AppContextPropsType from '../../../types/AppContextPropsType';

import {TextField, Checkbox} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {BureauObj, BureauRef} from '../../../types/models/admin/Bureau';
import {useBureauList} from '../../../redux/actions/BureauApp';

import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;



export interface ObjetWithBureau {
  bureau?: BureauRef | null;
}

export interface ObjetWithBureaux {
  bureaux?: BureauRef[] | null;
}


export interface SelectionBureauxObj { // TODO: Move to a global location
  bureaux: BureauObj[],
}

const useSelectionBureaux = createLocalStorageStateHook<SelectionBureauxObj>('selectionBureaux', { bureaux: [] });

export function getSelectionBureaux() : SelectionBureauxObj { // TODO: Dangerous, but needed because other components can't always use hooks
  const value = localStorage.getItem('selectionBureaux');
  return value ? JSON.parse(value) as SelectionBureauxObj : { bureaux: [] };
};

export function setSelectionBureauxInStorage(value: SelectionBureauxObj) { // TODO: Dangerous, but needed because other components can't always use hooks
  localStorage.setItem('selectionBureaux', JSON.stringify(value));
};

export function filterBureauBySelection<T extends ObjetWithBureau>(value: T[]) : T[] {
  const selection = getSelectionBureaux();
  if (selection.bureaux.length === 0) return [];
  return value.filter(
    objet => selection.bureaux.some(sb => objet.bureau && objet.bureau.id === sb.id)
  );
};

export function filterBureauxBySelection<T extends ObjetWithBureaux>(value: T[]) : T[] {
  const selection = getSelectionBureaux();
  if (selection.bureaux.length === 0) return [];
  return value.filter(
    objet => selection.bureaux.some(sb => objet.bureaux && objet.bureaux.some(ob => ob.id === sb.id))
  );
};

export function filterParentBureauBySelection<T, U>(value: T[], data: U, getBureau: (value: T, data: U) => BureauRef | null | undefined) : T[] {
  const selection = getSelectionBureaux();
  if (selection.bureaux.length === 0) return [];
  return value.filter(
    objet => selection.bureaux.some(sb => getBureau(objet, data)?.id === sb.id)
  );
};

export function filterParentBureauxBySelection<T, U>(value: T[], data: U, getBureaux: (value: T, data: U) => BureauRef[]) : T[] {
  const selection = getSelectionBureaux();
  if (selection.bureaux.length === 0) return [];
  return value.filter(
    objet => selection.bureaux.some(sb => getBureaux(objet, data).some(ob => ob.id === sb.id)) // getBureaux should return an empty array instead of null | undefined
  );
};














const AppLogo = () => {
  const {themeMode} = useContext<AppContextPropsType>(AppContext);
  const useStyles = makeStyles(() => ({
    logoRoot: {
      display: 'flex',
      flexDirection: 'row',
      cursor: 'pointer',
      alignItems: 'center',
    },
    logo: {
      height: 36,
      marginRight: 10,
    },
  }));
  const classes = useStyles();

  const user = useAuthUser();
  const isAdmin = !cannotEditAdmin();
  const bureauList = useBureauList().filter(
    objet => user && (isAdmin || user.bureaux.some(sb => objet && objet.id === sb.id)) // The non-admin user can only see the bureaux mentioned in his profile
  );

  const [selection, setSelectionBureaux] = useSelectionBureaux();
  const [isOpen, setIsOpen] = React.useState(false);
  const [changed, setChanged] = React.useState(false);

  return (
    <Box className={classes.logoRoot}>
      <img
        className={classes.logo}
        src={ // TODO: If ok, remove the following two <Hidden> elements
          themeMode === ThemeMode.DARK
            ? '/assets/images/logo-white-with-name.png'
            : '/assets/images/logo-with-name.png'
        }
        alt='logo'
      />
      <Hidden smUp xsDown>
        <img
          className={classes.logo}
          src={
            themeMode === ThemeMode.DARK
              ? '/assets/images/logo-white.png'
              : '/assets/images/logo.png'
          }
          alt='logo'
        />
      </Hidden>
      <Hidden xsDown smUp>
        <img
          className={classes.logo}
          src={
            themeMode === ThemeMode.DARK
              ? '/assets/images/logo-white-with-name.png'
              : '/assets/images/logo-with-name.png'
          }
          alt='logo'
        />
      </Hidden>


      <Autocomplete
        //className={classes.widthFull}
        multiple
        disableCloseOnSelect
        options={bureauList}
        getOptionLabel={(option) => option.nom}
        getOptionSelected={(option, value) => option.id === value.id}
        value={selection.bureaux}
        open={isOpen}
        onOpen={() => setIsOpen(true)}
        onClose={() => { setIsOpen(false); if (changed) { window.location.reload(); setChanged(false); } }}
        onChange={(event, value) => { setSelectionBureaux({ bureaux: value }); if (isOpen) setChanged(true); else window.location.reload(); }}
        renderOption={(option, { selected }) => (
          <React.Fragment>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.nom}
          </React.Fragment>
        )}
        style={{ width: '100%', minWidth: 200 }}
        renderInput={(params) => (
          <TextField variant='standard' {...params} error={selection.bureaux.length === 0} />
        )}
      />
    </Box>
  );
};

export default AppLogo;
