import {
  RequerantActions,
  CREATE_NEW_REQUERANT,
  DELETE_REQUERANT,
  GET_REQUERANT_LIST,
  UPDATE_REQUERANT_DETAIL,
} from '../../types/actions/Requerant.actions';
import {RequerantObj} from '../../types/models/global/Requerant';

const initialState: {
  objetList: RequerantObj[];
  totalObjets: number;
  selectedObjet: RequerantObj | null;
} = {
  objetList: [],
  totalObjets: 0,
  selectedObjet: null,
};

const RequerantApp = (state = initialState, action: RequerantActions) => {
  switch (action.type) {
    case GET_REQUERANT_LIST:
      return {
        ...state,
        objetList: action.payload.list,
        totalObjets: action.payload.total,
      };

    case CREATE_NEW_REQUERANT:
      return {
        ...state,
        objetList: [action.payload, ...state.objetList],
        totalObjets: state.totalObjets + 1,
      };

    case DELETE_REQUERANT: {
      return {
        ...state,
        objetList: action.payload.list,
        totalObjets: action.payload.total,
      };
    }

    case UPDATE_REQUERANT_DETAIL:
      return {
        ...state,
        selectedObjet: action.payload,
        objetList: state.objetList.map(objet =>
          objet.id === action.payload.id ? action.payload : objet,
        ),
      };

    default:
      return state;
  }
};
export default RequerantApp;
