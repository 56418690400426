import {BureauRef} from "../admin/Bureau";
import {Réglages, Clé, ConfigurationObj, ConfigurationList, getConfigurationListFromApi, createConfigurationInApi} from "./Configuration";
import périodeDeTempsDefaultList from './PériodeDeTempsDefaultList';
import {getZoneAndCréneauDefaultLists} from "../secretariat/DisponibilitéDV";
import {typeExamenDefaultList} from "../secretariat/DossierPatient";
import {dispatchEventAuthSignout} from "@crema/services/auth/jwt-auth/jwt-api";

export enum Secteur {
  Aucun = '',
  s78 = '78',
  s92 = '92',
  s93 = '93',
  s95 = '95',
  sDD = 'DD',
  sdpo = 'dpo',
  sPoi = 'Poi',
  sSG = 'SG', // === 'Saint Germain' // TODO: Remove if not used
  sNord = 'Nord',
  sSud = 'Sud',
}

const defaultRéglages : Réglages = {
  montantTVA: 20,
  tauxDeParticipationAuxFrais: 30,
  secteurs: Object.values(Secteur),
  notes: '',
}

function getConfigurationDefaultList(bureauList: BureauRef[]) : ConfigurationObj[] {
  const defaultLists = getZoneAndCréneauDefaultLists(bureauList);
  const obj: ConfigurationList = {
    périodesDeTemps: périodeDeTempsDefaultList,
    zones: defaultLists.dvZones,
    créneaux: defaultLists.dvCréneaux,
    typesDExamen: typeExamenDefaultList,
    réglages: defaultRéglages,
  };
  return [
    { clé: Clé.périodesDeTemps, valeur: obj.périodesDeTemps },
    { clé: Clé.zones, valeur: obj.zones },
    { clé: Clé.créneaux, valeur: obj.créneaux },
    { clé: Clé.typesDExamen, valeur: obj.typesDExamen },
    { clé: Clé.réglages, valeur: obj.réglages },
  ];
}

export async function seedConfigurationList(bureauList: BureauRef[]): Promise<void> {
  const configurationList = getConfigurationDefaultList(bureauList);
  for (const configuration of configurationList) {
    await createConfigurationInApi(configuration);
  }
}

export async function loadConfigurationList(bureauList?: BureauRef[]): Promise<void> {
  let configurationList = await getConfigurationListFromApi();
  if (!configurationList) {
    if (!bureauList) {
      dispatchEventAuthSignout(); // Configuration non-initialisée
      return;
    }
    await seedConfigurationList(bureauList);
    configurationList = await getConfigurationListFromApi();
    if (!configurationList) {
      throw new Error('Configuration impossible à initialiser');
    }
  }
  localStorage.setItem('configuration', JSON.stringify(configurationList));
}
