import {CommissariatObj} from '../models/global/Commissariat';

export const CREATE_NEW_COMMISSARIAT = 'CREATE_NEW_COMMISSARIAT';
export const DELETE_COMMISSARIAT = 'DELETE_COMMISSARIAT';
export const GET_COMMISSARIAT_LIST = 'GET_COMMISSARIAT_LIST';
export const UPDATE_COMMISSARIAT_DETAIL = 'UPDATE_COMMISSARIAT_DETAIL';

export interface GetObjetsActions {
  type: typeof GET_COMMISSARIAT_LIST;
  payload: {list: CommissariatObj[]; total: number};
}

export interface UpdateObjetActions {
  type: typeof UPDATE_COMMISSARIAT_DETAIL;
  payload: CommissariatObj;
}

export interface CreateObjetActions {
  type: typeof CREATE_NEW_COMMISSARIAT;
  payload: CommissariatObj;
}

export interface DeleteObjetActions {
  type: typeof DELETE_COMMISSARIAT;
  payload: {list: CommissariatObj[]; total: number};
}

export type CommissariatActions =
  | GetObjetsActions
  | UpdateObjetActions
  | CreateObjetActions
  | DeleteObjetActions;
