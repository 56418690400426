import {DossierPatientObj} from '../models/secretariat/DossierPatient';

export const CREATE_NEW_DOSSIERPATIENT = 'CREATE_NEW_DOSSIERPATIENT';
export const DELETE_DOSSIERPATIENT = 'DELETE_DOSSIERPATIENT';
export const GET_DOSSIERPATIENT_LIST = 'GET_DOSSIERPATIENT_LIST';
export const UPDATE_DOSSIERPATIENT_DETAIL = 'UPDATE_DOSSIERPATIENT_DETAIL';

export interface GetObjetsActions {
  type: typeof GET_DOSSIERPATIENT_LIST;
  payload: {list: DossierPatientObj[]; total: number};
}

export interface UpdateObjetActions {
  type: typeof UPDATE_DOSSIERPATIENT_DETAIL;
  payload: DossierPatientObj;
}

export interface CreateObjetActions {
  type: typeof CREATE_NEW_DOSSIERPATIENT;
  payload: DossierPatientObj;
}

export interface DeleteObjetActions {
  type: typeof DELETE_DOSSIERPATIENT;
  payload: {list: DossierPatientObj[]; total: number};
}

export type DossierPatientActions =
  | GetObjetsActions
  | UpdateObjetActions
  | CreateObjetActions
  | DeleteObjetActions;
