import {DisponibilitéRVObj} from '../models/secretariat/DisponibilitéRV';

export const CREATE_NEW_DISPONIBILITÉRV = 'CREATE_NEW_DISPONIBILITÉRV';
export const DELETE_DISPONIBILITÉRV = 'DELETE_DISPONIBILITÉRV';
export const GET_DISPONIBILITÉRV_LIST = 'GET_DISPONIBILITÉRV_LIST';
export const UPDATE_DISPONIBILITÉRV_DETAIL = 'UPDATE_DISPONIBILITÉRV_DETAIL';

export interface GetObjetsActions {
  type: typeof GET_DISPONIBILITÉRV_LIST;
  payload: {list: DisponibilitéRVObj[]; total: number};
}

export interface UpdateObjetActions {
  type: typeof UPDATE_DISPONIBILITÉRV_DETAIL;
  payload: DisponibilitéRVObj;
}

export interface CreateObjetActions {
  type: typeof CREATE_NEW_DISPONIBILITÉRV;
  payload: DisponibilitéRVObj;
}

export interface DeleteObjetActions {
  type: typeof DELETE_DISPONIBILITÉRV;
  payload: {list: DisponibilitéRVObj[]; total: number};
}

export type DisponibilitéRVActions =
  | GetObjetsActions
  | UpdateObjetActions
  | CreateObjetActions
  | DeleteObjetActions;
