import React from 'react';
import {Redirect} from 'react-router-dom';

import {createRoutes} from '../@crema/utility/Utils';
import {samplePagesConfig} from './sample';
import {errorPagesConfigs} from './errorPages';
import {authRouteConfig} from './auth';
import {initialUrl} from '../shared/constants/AppConst';

import {adminConfig} from './admin';
import {configurationConfig} from './configuration';
import {examinateurConfig} from './examinateur';
import {globalConfig} from './global';
import {publicConfig} from './public';
import {rapportsConfig, secretariatConfig} from './secretariat';

const routeConfigs = [
  ...samplePagesConfig,
  ...errorPagesConfigs,
  ...authRouteConfig,
  ...adminConfig,
  ...configurationConfig,
  ...examinateurConfig,
  ...globalConfig,
  ...publicConfig,
  ...secretariatConfig,
  ...rapportsConfig
];

const routes = [
  ...createRoutes(routeConfigs),
  {
    path: '/',
    exact: true,
    component: () => <Redirect to={initialUrl} />,
  },
  {
    component: () => <Redirect to='/error-pages/error-404' />,
  },
];

export default routes;
