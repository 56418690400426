import React from 'react';
import {Redirect} from 'react-router-dom';
import {authRole} from '../../shared/constants/AppConst';

export const rapportsConfig = [
  {
    auth: authRole.any,
    routes: [
      {
        path: ['/secretariat/dprapport'],
        component: React.lazy(() => import('./DossierPatient/Modifier/RapportLire')),
      }
    ]
  }
];

export const secretariatConfig = [
  {
    auth: authRole.anySecretariat,
    routes: [
      {
        path: ['/secretariat/rendez-vous/medecin'],
        component: React.lazy(() => import('./RendezVous')),
      },
      {
        path: ['/secretariat/rendez-vous/psychologue'],
        component: React.lazy(() => import('./RendezVous')),
      },
      {
        path: ['/secretariat/demande-de-visite'],
        component: React.lazy(() => import('./DemandeDeVisite')),
      },
      {
        path: ['/secretariat/disponibiliterv/medecin'],
        component: React.lazy(() => import('./DisponibilitéRV/Liste')),
      },
      {
        path: ['/secretariat/disponibiliterv/psychologue'],
        component: React.lazy(() => import('./DisponibilitéRV/Liste')),
      },
      {
        path: ['/secretariat/disponibilitedv'],
        component: React.lazy(() => import('./DisponibilitéDV/Liste')),
      },
      {
        path: ['/secretariat/dossier-patient/liste'],
        component: React.lazy(() => import('./DossierPatient/Liste')),
      },
      {
        path: '/secretariat/dossier-patient',
        component: () => <Redirect to='/secretariat/dossier-patient/liste' />,
      },
      {
        path: ['/secretariat/bordereau/nouveau'],
        component: React.lazy(() => import('./DossierPatient/Liste')),
      },
      {
        path: ['/secretariat/bordereau/afficher'],
        component: React.lazy(() => import('./DossierPatient/Liste')),
      },
      {
        path: ['/secretariat/bordereau/liste'],
        component: React.lazy(() => import('./Bordereau/Liste')),
      },
      {
        path: '/secretariat/bordereau',
        component: () => <Redirect to='/secretariat/bordereau/liste' />,
      },
      {
        path: ['/secretariat/repartition-paiement/liste'],
        component: React.lazy(() => import('./RépartitionPaiement/Liste')),
      },
      {
        path: '/secretariat/repartition-paiement',
        component: () => <Redirect to='/secretariat/repartition-paiement/liste' />,
      },
      {
        path: ['/secretariat/statistiques'],
        component: React.lazy(() => import('./Rapports/Statistiques')),
      },
      {
        path: ['/secretariat/comptabilite'],
        component: React.lazy(() => import('./Rapports/Comptabilité')),
      },
    ],
  },
];
