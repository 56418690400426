import {
  DPRapportActions,
  CREATE_NEW_DPRAPPORT,
  DELETE_DPRAPPORT,
  GET_DPRAPPORT_LIST,
  UPDATE_DPRAPPORT_DETAIL,
} from '../../types/actions/DPRapport.actions';
import {DPRapportObj} from '../../types/models/configuration/DPRapport';

const initialState: {
  objetList: DPRapportObj[];
  totalObjets: number;
  selectedObjet: DPRapportObj | null;
} = {
  objetList: [],
  totalObjets: 0,
  selectedObjet: null,
};

const DPRapportApp = (state = initialState, action: DPRapportActions) => {
  switch (action.type) {
    case GET_DPRAPPORT_LIST:
      return {
        ...state,
        objetList: action.payload.list,
        totalObjets: action.payload.total,
      };

    case CREATE_NEW_DPRAPPORT:
      return {
        ...state,
        objetList: [action.payload, ...state.objetList],
        totalObjets: state.totalObjets + 1,
      };

    case DELETE_DPRAPPORT: {
      return {
        ...state,
        objetList: action.payload.list,
        totalObjets: action.payload.total,
      };
    }

    case UPDATE_DPRAPPORT_DETAIL:
      return {
        ...state,
        selectedObjet: action.payload,
        objetList: state.objetList.map(objet =>
          objet.id === action.payload.id ? action.payload : objet,
        ),
      };

    default:
      return state;
  }
};
export default DPRapportApp;
