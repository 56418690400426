import React from 'react';
import {useSelector} from 'react-redux';
import {AppState} from 'redux/store';
import {Loader, MessageView} from '@crema';
import Dialog from '@material-ui/core/Dialog';
import {makeStyles} from '@material-ui/core/styles/index';

const useStyles = makeStyles(() => ({
  dialogBox: { // Remove the backdrop & background color
    '& > .MuiBackdrop-root': {
      opacity: '0 !important',
    },
    '& > .MuiDialog-container > .MuiPaper-root': {
      backgroundColor: 'transparent',
    },
  },
}));

interface InfoViewProps {}

const InfoView: React.FC<InfoViewProps> = () => {
  const classes = useStyles();

  const {error, loading, message} = useSelector<AppState, AppState['common']>(
    ({common}) => common,
  );

  const [displayingMessage, setDisplayingMessage] = React.useState(false);

  React.useEffect(() => {
    setDisplayingMessage(!!message || !!error);
  }, [message, error]);

  function onClosed() {
    setDisplayingMessage(false);
  }

  const showMessage = React.useCallback(() => {
    return <MessageView variant='success' message={message.toString()} onClosed={onClosed} />;
  }, [message]);

  const showError = React.useCallback(() => {
    return <MessageView variant='error' message={error.toString()} onClosed={onClosed} />;
  }, [error]);

  return (
    <Dialog
      open={loading || displayingMessage}
      fullScreen={true}
      className={classes.dialogBox}>
      {loading && <Loader />}

      {message && showMessage()}
      {error && showError()}
    </Dialog>
  );
};

export default InfoView;
