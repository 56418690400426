
export const constRole = {
  aucun: 'aucun',
  administrateur: 'administrateur',
  secretariat: 'secretariat',
  secretariatExterne: 'secretariat-externe',
  medecin: 'medecin',
  psychologue: 'psychologue',
};

export const authRole = {
  any: [constRole.administrateur, constRole.secretariat, constRole.secretariatExterne, constRole.medecin, constRole.psychologue],
  anySecretariat: [constRole.administrateur, constRole.secretariat, constRole.secretariatExterne],
  anyExaminateur: [constRole.medecin, constRole.psychologue],
  admin: [constRole.administrateur],
  secretariat: [constRole.administrateur, constRole.secretariat],
  secretariatExterne: [constRole.secretariatExterne],
  secretariatNonAdmin: [constRole.secretariat, constRole.secretariatExterne],
  medecin: [constRole.medecin],
  psychologue: [constRole.psychologue]
};

export const initialUrl = '/initialUrl'; // this url will open after login
