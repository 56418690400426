import {RequerantObj} from '../models/global/Requerant';

export const CREATE_NEW_REQUERANT = 'CREATE_NEW_REQUERANT';
export const DELETE_REQUERANT = 'DELETE_REQUERANT';
export const GET_REQUERANT_LIST = 'GET_REQUERANT_LIST';
export const UPDATE_REQUERANT_DETAIL = 'UPDATE_REQUERANT_DETAIL';

export interface GetObjetsActions {
  type: typeof GET_REQUERANT_LIST;
  payload: {list: RequerantObj[]; total: number};
}

export interface UpdateObjetActions {
  type: typeof UPDATE_REQUERANT_DETAIL;
  payload: RequerantObj;
}

export interface CreateObjetActions {
  type: typeof CREATE_NEW_REQUERANT;
  payload: RequerantObj;
}

export interface DeleteObjetActions {
  type: typeof DELETE_REQUERANT;
  payload: {list: RequerantObj[]; total: number};
}

export type RequerantActions =
  | GetObjetsActions
  | UpdateObjetActions
  | CreateObjetActions
  | DeleteObjetActions;
