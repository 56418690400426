import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AppState} from '../store';
import Api from '../../@crema/services/ApiConfig';
import {appIntl} from '../../@crema/utility/Utils';
import {Dispatch} from 'redux';
import {AppActions} from '../../types';
import {fetchError, fetchStart, fetchSuccess, showMessage} from './Common';
import {
  CREATE_NEW_BUREAU,
  DELETE_BUREAU,
  GET_BUREAU_LIST,
  UPDATE_BUREAU_DETAIL,
} from '../../types/actions/Bureau.actions';
import {BureauObj, BureauRef} from '../../types/models/admin/Bureau';


export const getBureaux = async () : Promise<BureauObj[]> => {
  const response = await Api.get('/bureaux/');
  return response.data as BureauObj[];
};


export const onGetObjetList = (
  type: string, name: string, currentPage: number, // TODO: Remove unused params
) => {
  const {messages} = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    Api.get('/bureaux/')
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({type: GET_BUREAU_LIST, payload: {list: data.data, total: data.data.length}});
        } else {
          dispatch(
            fetchError(messages['message.somethingWentWrong'] as string),
          );
        }
      })
      .catch(error => {
        dispatch(fetchError(error.response?.data?.message ?? error.message));
      });
  };
};

export const onDeleteObjets = (
  type: string, name: string, // TODO: Remove unused params
  objetIds: string[],
  page: number, // TODO: Remove unused params
) => {
  const {messages} = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    Api.delete('/bureaux/', {
      data: { ids: objetIds },
    })
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          Api.get('/bureaux/')
            .then(data => dispatch({type: DELETE_BUREAU, payload: {list: data.data, total: data.data.length}}));
          dispatch(showMessage(messages['message.objetDeleted'] as string));
        } else {
          dispatch(
            fetchError(messages['message.somethingWentWrong'] as string),
          );
        }
      })
      .catch(error => {
        dispatch(fetchError(error.response?.data?.message ?? error.message));
      });
  };
};

export const onUpdateSelectedObjet = (objet: BureauObj) => {
  const {messages} = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    Api.patch('/bureaux/' + objet.id, objet)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({type: UPDATE_BUREAU_DETAIL, payload: data.data});
          dispatch(showMessage(messages['message.objetUpdated'] as string));
        } else {
          dispatch(
            fetchError(messages['message.somethingWentWrong'] as string),
          );
        }
      })
      .catch(error => {
        dispatch(fetchError(error.response?.data?.message ?? error.message));
      });
  };
};

export const onCreateObjet = (objet: BureauObj) => {
  const {messages} = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    Api.post('/bureaux/', objet)
      .then(data => {
        if (data.status === 201) {
          dispatch(fetchSuccess());
          dispatch({type: CREATE_NEW_BUREAU, payload: data.data});
          dispatch(showMessage(messages['message.objetCreated'] as string));
        } else {
          dispatch(
            fetchError(messages['message.somethingWentWrong'] as string),
          );
        }
      })
      .catch(error => {
        dispatch(fetchError(error.response?.data?.message ?? error.message));
      });
  };
};




export const useBureauList = () => {
  const {objetList} = useSelector<AppState, AppState['bureauApp']>(({bureauApp}) => bureauApp);
  const dispatch = useDispatch();
  React.useEffect(() => {
    if (objetList.length === 0) {
      dispatch(
        onGetObjetList('', '', 0) // TODO: Remove unused params
      );
    }
  }, [objetList, dispatch]);
  return objetList;
};

export const useBureauRefList = () : BureauRef[] => {
  return useBureauList().map(b => {return {id: b.id, nom: b.nom};});
}