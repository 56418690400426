import {
  DossierPatientActions,
  CREATE_NEW_DOSSIERPATIENT,
  DELETE_DOSSIERPATIENT,
  GET_DOSSIERPATIENT_LIST,
  UPDATE_DOSSIERPATIENT_DETAIL,
} from '../../types/actions/DossierPatient.actions';
import {DossierPatientObj} from '../../types/models/secretariat/DossierPatient';

const initialState: {
  objetList: DossierPatientObj[];
  totalObjets: number;
  selectedObjet: DossierPatientObj | null;
} = {
  objetList: [],
  totalObjets: 0,
  selectedObjet: null,
};

const DossierPatientApp = (state = initialState, action: DossierPatientActions) => {
  switch (action.type) {
    case GET_DOSSIERPATIENT_LIST:
      return {
        ...state,
        objetList: action.payload.list,
        totalObjets: action.payload.total,
      };

    case CREATE_NEW_DOSSIERPATIENT:
      return {
        ...state,
        objetList: [action.payload, ...state.objetList],
        totalObjets: state.totalObjets + 1,
      };

    case DELETE_DOSSIERPATIENT: {
      return {
        ...state,
        objetList: action.payload.list,
        totalObjets: action.payload.total,
      };
    }

    case UPDATE_DOSSIERPATIENT_DETAIL:
      return {
        ...state,
        selectedObjet: action.payload,
        objetList: state.objetList.map(objet =>
          objet.id === action.payload.id ? action.payload : objet,
        ),
      };

    default:
      return state;
  }
};
export default DossierPatientApp;
