import {
  BordereauActions,
  CREATE_NEW_BORDEREAU,
  DELETE_BORDEREAU,
  GET_BORDEREAU_LIST,
  UPDATE_BORDEREAU_DETAIL,
} from '../../types/actions/Bordereau.actions';
import {BordereauObj} from '../../types/models/secretariat/Bordereau';

const initialState: {
  objetList: BordereauObj[];
  totalObjets: number;
  selectedObjet: BordereauObj | null;
} = {
  objetList: [],
  totalObjets: 0,
  selectedObjet: null,
};

const BordereauApp = (state = initialState, action: BordereauActions) => {
  switch (action.type) {
    case GET_BORDEREAU_LIST:
      return {
        ...state,
        objetList: action.payload.list,
        totalObjets: action.payload.total,
      };

    case CREATE_NEW_BORDEREAU:
      return {
        ...state,
        objetList: [action.payload, ...state.objetList],
        totalObjets: state.totalObjets + 1,
      };

    case DELETE_BORDEREAU: {
      return {
        ...state,
        objetList: action.payload.list,
        totalObjets: action.payload.total,
      };
    }

    case UPDATE_BORDEREAU_DETAIL:
      return {
        ...state,
        selectedObjet: action.payload,
        objetList: state.objetList.map(objet =>
          objet.id === action.payload.id ? action.payload : objet,
        ),
      };

    default:
      return state;
  }
};
export default BordereauApp;
