import React from 'react';
import {Redirect} from 'react-router-dom';
import {authRole} from '../../shared/constants/AppConst';

export const examinateurConfig = [
  {
    auth: authRole.medecin,
    routes: [
      {
        path: ['/examinateur/rendez-vous/medecin'],
        component: React.lazy(() => import('../secretariat/RendezVous')),
      },
      {
        path: ['/examinateur/demande-de-visite'],
        component: React.lazy(() => import('../secretariat/DemandeDeVisite')),
      },
      {
        path: ['/examinateur/disponibiliterv/medecin'],
        component: React.lazy(() => import('../secretariat/DisponibilitéRV/Liste')),
      },
      {
        path: ['/examinateur/disponibilitedv'],
        component: React.lazy(() => import('../secretariat/DisponibilitéDV/Liste')),
      },
    ],
  },
  {
    auth: authRole.psychologue,
    routes: [
      {
        path: ['/examinateur/rendez-vous/psychologue'],
        component: React.lazy(() => import('../secretariat/RendezVous')),
      },
      {
        path: ['/examinateur/disponibiliterv/psychologue'],
        component: React.lazy(() => import('../secretariat/DisponibilitéRV/Liste')),
      },
    ],
  },
  {
    auth: authRole.anyExaminateur,
    routes: [
      {
        path: ['/examinateur/dossier-patient/liste'],
        component: React.lazy(() => import('../secretariat/DossierPatient/Liste')),
      },
      {
        path: '/examinateur/dossier-patient',
        component: () => <Redirect to='/examinateur/dossier-patient/liste' />,
      },
      {
        path: ['/examinateur/statistiques'],
        component: React.lazy(() => import('../secretariat/Rapports/Statistiques')),
      },
      {
        path: ['/examinateur/comptabilite'],
        component: React.lazy(() => import('../secretariat/Rapports/Comptabilité')),
      },
    ],
  },
];
