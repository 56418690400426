import {BordereauObj} from '../models/secretariat/Bordereau';

export const CREATE_NEW_BORDEREAU = 'CREATE_NEW_BORDEREAU';
export const DELETE_BORDEREAU = 'DELETE_BORDEREAU';
export const GET_BORDEREAU_LIST = 'GET_BORDEREAU_LIST';
export const UPDATE_BORDEREAU_DETAIL = 'UPDATE_BORDEREAU_DETAIL';

export interface GetObjetsActions {
  type: typeof GET_BORDEREAU_LIST;
  payload: {list: BordereauObj[]; total: number};
}

export interface UpdateObjetActions {
  type: typeof UPDATE_BORDEREAU_DETAIL;
  payload: BordereauObj;
}

export interface CreateObjetActions {
  type: typeof CREATE_NEW_BORDEREAU;
  payload: BordereauObj;
}

export interface DeleteObjetActions {
  type: typeof DELETE_BORDEREAU;
  payload: {list: BordereauObj[]; total: number};
}

export type BordereauActions =
  | GetObjetsActions
  | UpdateObjetActions
  | CreateObjetActions
  | DeleteObjetActions;
