import {authRole} from "shared/constants/AppConst";

export interface NavItemProps {
  id: string;
  title?: string;
  messageId?: string;
  icon?: string;
  exact?: boolean;
  url?: string;
  type?: string;
  count?: number;
  color?: string;
  auth?: string[];
  excludedIf?: { bureauNom: string, role?: string[] };
  children?: NavItemProps[] | NavItemProps;
}

// https://materialui.co/icons
const routesConfig: NavItemProps[] = [
  {
    id: 'examinateur',
    title: 'Examinateur',
    type: 'group',
    icon: 'local_hospital',
    auth: authRole.anyExaminateur,
    children: [
      {
        id: 'examinateur-rendez-vous-medecin',
        title: 'Rendez-Vous ITT',
        type: 'item',
        icon: 'event_note',
        url: '/examinateur/rendez-vous/medecin',
        auth: authRole.medecin,
        excludedIf: { bureauNom: 'Paris Nord' },
      },
      {
        id: 'examinateur-rendez-vous-psychologue',
        title: 'Rendez-Vous',
        type: 'item',
        icon: 'event_available',
        url: '/examinateur/rendez-vous/psychologue',
        auth: authRole.psychologue,
        excludedIf: { bureauNom: 'Paris Nord' },
      },
      {
        id: 'examinateur-demande-de-visite',
        title: 'Demandes de Visite',
        type: 'item',
        icon: 'time_to_leave',
        url: '/examinateur/demande-de-visite',
        auth: authRole.medecin,
      },
      {
        id: 'dossier-patient',
        title: 'Dossiers Patient',
        type: 'item',
        icon: 'sick',
        url: '/examinateur/dossier-patient',
        auth: authRole.anyExaminateur,
      },
      {
        id: 'examinateur-disponibiliterv-medecin',
        title: 'Disponibilités ITT',
        type: 'item',
        icon: 'healing',
        url: '/examinateur/disponibiliterv/medecin',
        auth: authRole.medecin,
        excludedIf: { bureauNom: 'Paris Nord' },
      },
      {
        id: 'examinateur-disponibiliterv-psychologue',
        title: 'Disponibilités',
        type: 'item',
        icon: 'psychology',
        url: '/examinateur/disponibiliterv/psychologue',
        auth: authRole.psychologue,
        excludedIf: { bureauNom: 'Paris Nord' },
      },
      {
        id: 'examinateur-disponibilitedv',
        title: 'Disponibilités GAV',
        type: 'item',
        icon: 'map',
        url: '/examinateur/disponibilitedv',
        auth: authRole.medecin,
      },
      {
        id: 'statistiques',
        title: 'Statistiques',
        type: 'item',
        icon: 'analytics',
        url: '/examinateur/statistiques',
        auth: authRole.anyExaminateur,
      },
      {
        id: 'comptabilite',
        title: 'Comptabilité',
        type: 'item',
        icon: 'euro',
        url: '/examinateur/comptabilite',
        auth: authRole.anyExaminateur,
      },
    ],
  },
  {
    id: 'secretariat',
    title: 'Secrétariat',
    type: 'group',
    icon: 'work',
    auth: authRole.anySecretariat,
    children: [
      {
        id: 'rendez-vous-medecin',
        title: 'Rendez-Vous ITT',
        type: 'item',
        icon: 'event_note',
        url: '/secretariat/rendez-vous/medecin',
        auth: authRole.anySecretariat,
        excludedIf: { bureauNom: 'Paris Nord' },
      },
      {
        id: 'rendez-vous-psychologue',
        title: 'Rendez-Vous Psy',
        type: 'item',
        icon: 'event_available',
        url: '/secretariat/rendez-vous/psychologue',
        auth: authRole.secretariat,
        excludedIf: { bureauNom: 'Paris Nord' },
      },
      {
        id: 'demande-de-visite',
        title: 'Demandes de Visite',
        type: 'item',
        icon: 'time_to_leave',
        url: '/secretariat/demande-de-visite',
        auth: authRole.anySecretariat,
        excludedIf: { bureauNom: 'Chartres', role: authRole.secretariatExterne },
      },
      {
        id: 'dossier-patient',
        title: 'Dossiers Patient',
        type: 'item',
        icon: 'sick',
        url: '/secretariat/dossier-patient',
        auth: authRole.secretariat,
      },
      {
        id: 'disponibiliterv-medecin',
        title: 'Disponibilités ITT',
        type: 'item',
        icon: 'healing',
        url: '/secretariat/disponibiliterv/medecin',
        auth: authRole.secretariat,
        excludedIf: { bureauNom: 'Paris Nord' },
      },
      {
        id: 'disponibiliterv-psychologue',
        title: 'Disponibilités Psy',
        type: 'item',
        icon: 'psychology',
        url: '/secretariat/disponibiliterv/psychologue',
        auth: authRole.secretariat,
        excludedIf: { bureauNom: 'Paris Nord' },
      },
      {
        id: 'disponibilitedv',
        title: 'Disponibilités GAV',
        type: 'item',
        icon: 'map',
        url: '/secretariat/disponibilitedv',
        auth: authRole.secretariat,
      },
      {
        id: 'bordereau',
        title: 'Bordereaux',
        type: 'item',
        icon: 'folder',
        url: '/secretariat/bordereau',
        auth: authRole.secretariat,
      },
      {
        id: 'repartition-paiement',
        title: 'Répartition des Paiements',
        type: 'item',
        icon: 'share',
        url: '/secretariat/repartition-paiement',
        auth: authRole.secretariat,
      },
      {
        id: 'statistiques',
        title: 'Statistiques',
        type: 'item',
        icon: 'analytics',
        url: '/secretariat/statistiques',
        auth: authRole.secretariat,
      },
      {
        id: 'comptabilite',
        title: 'Comptabilité',
        type: 'item',
        icon: 'euro',
        url: '/secretariat/comptabilite',
        auth: authRole.secretariat,
      },
    ],
  },
  {
    id: 'global',
    title: 'Global',
    type: 'group',
    icon: 'public',
    children: [
      {
        id: 'commissariat',
        title: 'Commissariats',
        type: 'item',
        icon: 'local_police',
        url: '/global/commissariat',
        auth: authRole.any,
      },
      {
        id: 'requerant',
        title: 'Requérants',
        type: 'item',
        icon: 'account_balance',
        url: '/global/requerant',
        auth: authRole.secretariat,
      },
      {
        id: 'examinateurs',
        title: 'Examinateurs',
        type: 'item',
        icon: 'people',
        url: '/global/examinateur',
        auth: [...authRole.secretariatNonAdmin, ...authRole.anyExaminateur],
      },
      {
        id: 'documentsvierges',
        title: 'Documents Vierges',
        type: 'item',
        icon: 'download',
        url: '/global/documentsvierges',
        auth: [...authRole.secretariat, ...authRole.anyExaminateur],
      },
    ],
  },
  {
    id: 'configuration',
    title: 'Configuration',
    type: 'group',
    icon: 'engineering',
    auth: authRole.admin,
    children: [
      {
        id: 'dprapport',
        title: 'Rapports (Dossier Patient)',
        type: 'item',
        icon: 'article',
        url: '/configuration/dprapport',
      },
      {
        id: 'dvzone',
        title: 'Zones (GAV)',
        type: 'item',
        icon: 'place',
        url: '/configuration/dvzone',
      },
      {
        id: 'dvcreneau',
        title: 'Créneaux (GAV)',
        type: 'item',
        icon: 'schedule',
        url: '/configuration/dvcreneau',
      },
      {
        id: 'typeexamen',
        title: 'Types d\'examen',
        type: 'item',
        icon: 'receipt_long',
        url: '/configuration/typeexamen',
      },
      {
        id: 'reglages',
        title: 'Réglages',
        type: 'item',
        icon: 'settings',
        url: '/configuration/reglages',
      },
    ],
  },
  {
    id: 'admin',
    title: 'Administration',
    type: 'group',
    icon: 'admin_panel_settings',
    auth: authRole.admin,
    children: [
      {
        id: 'utilisateur',
        title: 'Utilisateurs',
        type: 'item',
        icon: 'people',
        url: '/admin/utilisateur',
      },
      {
        id: 'bureau',
        title: 'Bureaux',
        type: 'item',
        icon: 'apartment',
        url: '/admin/bureau',
      },
    ],
  },
  /*{
    id: 'sample', // TODO: Remove completely
    title: 'Sample',
    messageId: 'sidebar.sample',
    type: 'group',
    icon: 'dashboard',
    children: [
      {
        id: 'page-1',
        title: 'Page -1',
        messageId: 'sidebar.sample.page1',
        type: 'item',
        url: '/sample/page-1',
        auth: authRole.secretariat
      },
      {
        id: 'page-2',
        title: 'Page -2',
        messageId: 'sidebar.sample.page2',
        type: 'item',
        url: '/sample/page-2',
        auth: authRole.anySecretariat
      },
      {
        id: 'page-3',
        title: 'Page -3',
        messageId: 'sidebar.sample.page3',
        type: 'item',
        url: '/sample/page-3',
        auth: authRole.anyExaminateur
      }
    ],
  },*/
];
export default routesConfig;
