import moment, {Moment} from 'moment';
import {BureauObj} from '../admin/Bureau';
import {ExaminateurRef} from '../admin/Utilisateur';
import {CommissariatRef} from '../global/Commissariat';
import {Lieu, Statut, constStatuts, MissionRef, filter} from './DossierPatient';
import {DisponibilitéRVObj} from './DisponibilitéRV';
import {getSelectionBureaux} from 'shared/components/AppLogo';

export interface RendezVousObj {
  id: number;
  lieu: Lieu;
  rendezVousDate?: number; // Nombre de jours depuis 1970
  rendezVousHeure?: number; // Nombre de minutes depuis minuit
  nom: string; // Nom du patient
  dateDeNaissance: string | null; // YYYY-MM-DD
  commissariat: CommissariatRef | null;
  examenType: MissionRef | null;
  examenConclusion: string; // = examenJoursITT if (RendezVousMedecin) || = Conclusion enum if (DemandeDeVisite)
  gavHeureProlongation: string | null; // HH:mm
  examinateur: ExaminateurRef | null;
  statut: Statut;
  notes: string;
  retourExaminateur: string;
  bureau: BureauObj;
}

const createEmptyObjet = (lieu: Lieu, date: number, heure: number) : RendezVousObj => {
  return {
    id: 0,
    lieu,
    rendezVousDate: date,
    rendezVousHeure: heure,
    nom: '',
    dateDeNaissance: null,
    commissariat: null,
    examenType: null,
    examenConclusion: '',
    gavHeureProlongation: null,
    examinateur: null,
    statut: constStatuts.Aucun,
    notes: '',
    retourExaminateur: '',
    bureau: null!, // Let prepareToSave() set it // TODO: OK?
  };
};

export const heureToString = (minutes?: number | null, separator?: string | null) => {
  if (minutes === null || minutes === undefined) return null;
  if (!separator) separator = 'h ';
  const nextDay = 24 * 60;
  while (minutes >= nextDay) // Happens with créneau.heureFin
    minutes -= nextDay;
  const hh = Math.trunc(minutes / 60);
  const mm = minutes % 60;
  return (hh < 10 ? '0' : '') + hh + separator + (mm < 10 ? '0' : '') + mm;
}

export function fromNumberOfDaysSince1970(days?: number | null) {
  if (days === null || days === undefined) return null;
  const m = moment('1970-01-01').add(days, 'days'); // Note: moment(0) is UTC, then converted to the local timezone (which adds an extra hour that makes the manipulations here be incorrect)
  return m;
}
export function toNumberOfDaysSince1970(date?: string | null) {
  if (date === null || date === undefined) return undefined;
  const m = moment(date);
  if (!m.isValid()) return undefined; // Happens while typing the date
  const days = m.diff(moment('1970-01-01'), 'days');
  return days;
}
export function fromNumberOfMinutes(minutes?: number | null) {
  if (minutes === null || minutes === undefined) return null;
  //if (isNaN(minutes)) return null;
  const date = '1970-01-01 ' + heureToString(minutes, ':');
  return moment(date);
}
export function toNumberOfMinutes(date?: Moment | string | null) {
  if (date === null || date === undefined) return undefined;
  const m = moment(date);
  if (!m.isValid()) return NaN; // TODO: Happens when deleting and typing a time. This seems like the only way to keep the picker from reseting. m.hours() also returns NaN. This line is only to make this behavior explicit. The formik validation prevents NaN from being saved.
  return m.hours() * 60 + m.minutes();
}



const createDisponible = (lieu: Lieu, date: number, heure: number, medecin: ExaminateurRef) : RendezVousObj => {
  return {
    ...createEmptyObjet(lieu, date, heure),
    examinateur: medecin,
  };
};

export const getDisponibles = (listeDisponibilitéRV : DisponibilitéRVObj[]) : RendezVousObj[] => {
  const objets: RendezVousObj[] = [];
  const interval = 15;
  for (const dispo of listeDisponibilitéRV)
    if (dispo.examinateurConfirmé)
      for (let minutes = dispo.heureDebut; minutes < dispo.heureFin; minutes += interval) {
        const objet = createDisponible(dispo.lieu, dispo.date, minutes, dispo.examinateurConfirmé);
        //objet.id = dispo.id; // Note: Editing this objet will trigger the creation of a DossierPatient, which will then take priority over this objet in the RDV list
        objets.push(objet);
      }
  return objets;
};

const getEmpties = (lieu: Lieu, date: number) : RendezVousObj[] => {
  const heureDebut = 8 * 60;
  const heureFinExcl = 20 * 60;
  const interval = 15;

  const objets: RendezVousObj[] = [];
  for (let minutes = heureDebut; minutes < heureFinExcl; minutes += interval) {
    objets.push(createEmptyObjet(lieu, date, minutes));
  }
  return objets;
};


function merge(a : RendezVousObj[], b : RendezVousObj[]) : RendezVousObj[] {
  const ret = [...a];

  for (let i = 0; i < b.length; i++){
      const e = b[i];
      if (!a.some(value => value.statut.id !== constStatuts.Annulé.id && value.rendezVousHeure && e.rendezVousHeure && Math.abs(value.rendezVousHeure - e.rendezVousHeure) < 8)) { // Do not put two rdvs that are less than 8 mins apart
          ret.push(e);
      }
  }

  return ret;
}

export const getRdvList = (listeDisponibilitéRV: DisponibilitéRVObj[], listeDossierPatient: RendezVousObj[], lieu: Lieu, date: number, search: string) => {
  const selection = getSelectionBureaux();
  if (selection.bureaux.length !== 1) return []; // TODO: Warning to user to pick exactly one

  let objets = listeDossierPatient;
  objets = merge(objets, getDisponibles(listeDisponibilitéRV));
  objets = merge(objets, getEmpties(lieu, date));
  objets = objets.sort((x, y) => (x.rendezVousHeure ?? 0) - (y.rendezVousHeure ?? 0));

  return filter(objets, search);
};
