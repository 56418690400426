import {RépartitionPaiementObj} from '../models/secretariat/RépartitionPaiement';

export const CREATE_NEW_REPARTITIONPAIEMENT = 'CREATE_NEW_REPARTITIONPAIEMENT';
export const DELETE_REPARTITIONPAIEMENT = 'DELETE_REPARTITIONPAIEMENT';
export const GET_REPARTITIONPAIEMENT_LIST = 'GET_REPARTITIONPAIEMENT_LIST';
export const UPDATE_REPARTITIONPAIEMENT_DETAIL = 'UPDATE_REPARTITIONPAIEMENT_DETAIL';

export interface GetObjetsActions {
  type: typeof GET_REPARTITIONPAIEMENT_LIST;
  payload: {list: RépartitionPaiementObj[]; total: number};
}

export interface UpdateObjetActions {
  type: typeof UPDATE_REPARTITIONPAIEMENT_DETAIL;
  payload: RépartitionPaiementObj;
}

export interface CreateObjetActions {
  type: typeof CREATE_NEW_REPARTITIONPAIEMENT;
  payload: RépartitionPaiementObj;
}

export interface DeleteObjetActions {
  type: typeof DELETE_REPARTITIONPAIEMENT;
  payload: {list: RépartitionPaiementObj[]; total: number};
}

export type RépartitionPaiementActions =
  | GetObjetsActions
  | UpdateObjetActions
  | CreateObjetActions
  | DeleteObjetActions;
