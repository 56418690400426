import {
  UtilisateurActions,
  CREATE_NEW_UTILISATEUR,
  DELETE_UTILISATEUR,
  GET_UTILISATEUR_LIST,
  UPDATE_UTILISATEUR_DETAIL,
} from '../../types/actions/Utilisateur.actions';
import {UtilisateurObj} from '../../types/models/admin/Utilisateur';

const initialState: {
  objetList: UtilisateurObj[];
  totalObjets: number;
  selectedObjet: UtilisateurObj | null;
} = {
  objetList: [],
  totalObjets: 0,
  selectedObjet: null,
};

const UtilisateurApp = (state = initialState, action: UtilisateurActions) => {
  switch (action.type) {
    case GET_UTILISATEUR_LIST:
      return {
        ...state,
        objetList: action.payload.list,
        totalObjets: action.payload.total,
      };

    case CREATE_NEW_UTILISATEUR:
      return {
        ...state,
        objetList: [action.payload, ...state.objetList],
        totalObjets: state.totalObjets + 1,
      };

    case DELETE_UTILISATEUR: {
      return {
        ...state,
        objetList: action.payload.list,
        totalObjets: action.payload.total,
      };
    }

    case UPDATE_UTILISATEUR_DETAIL:
      return {
        ...state,
        selectedObjet: action.payload,
        objetList: state.objetList.map(objet =>
          objet.id === action.payload.id ? action.payload : objet,
        ),
      };

    default:
      return state;
  }
};
export default UtilisateurApp;
