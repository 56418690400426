import React from 'react';

export const publicConfig = [
  {
    routes: [
      {
        path: '/acceuil',
        component: React.lazy(() => import('./Accueil')),
      },
    ],
  },
  {
    routes: [
      {
        path: '/aide-aux-victimes',
        component: React.lazy(() => import('./AideAuxVictimes')),
      },
    ],
  },
];
