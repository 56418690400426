import {
  CommissariatActions,
  CREATE_NEW_COMMISSARIAT,
  DELETE_COMMISSARIAT,
  GET_COMMISSARIAT_LIST,
  UPDATE_COMMISSARIAT_DETAIL,
} from '../../types/actions/Commissariat.actions';
import {CommissariatObj} from '../../types/models/global/Commissariat';

const initialState: {
  objetList: CommissariatObj[];
  totalObjets: number;
  selectedObjet: CommissariatObj | null;
} = {
  objetList: [],
  totalObjets: 0,
  selectedObjet: null,
};

const CommissariatApp = (state = initialState, action: CommissariatActions) => {
  switch (action.type) {
    case GET_COMMISSARIAT_LIST:
      return {
        ...state,
        objetList: action.payload.list,
        totalObjets: action.payload.total,
      };

    case CREATE_NEW_COMMISSARIAT:
      return {
        ...state,
        objetList: [action.payload, ...state.objetList],
        totalObjets: state.totalObjets + 1,
      };

    case DELETE_COMMISSARIAT: {
      return {
        ...state,
        objetList: action.payload.list,
        totalObjets: action.payload.total,
      };
    }

    case UPDATE_COMMISSARIAT_DETAIL:
      return {
        ...state,
        selectedObjet: action.payload,
        objetList: state.objetList.map(objet =>
          objet.id === action.payload.id ? action.payload : objet,
        ),
      };

    default:
      return state;
  }
};
export default CommissariatApp;
