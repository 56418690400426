import Api from '../../ApiConfig';
import {connectToSocket, disconnectFromSocket} from 'shared/Socket';

Api.interceptors.response.use(
  res => res,
  err => {
    const unauthorized = err?.response?.status === 401 || err?.response?.statusText === 'Unauthorized' || err?.response?.data?.statusCode === 401 || err?.response?.data?.message === 'Unauthorized';
    if (unauthorized) {
      console.log('Api.interceptors: Unauthorized => Logout user');
      dispatchEventAuthSignout();
    }
    return Promise.reject(err);
  },
);

export function setAuthToken(token: string | null) {
  if (token) {
    Api.defaults.headers['Authorization'] = `Bearer ${token}`;
    localStorage.setItem('token', token);
    connectToSocket(token);
  } else {
    delete Api.defaults.headers['Authorization'];
    localStorage.removeItem('token');
    disconnectFromSocket();
  }
};

export function dispatchEventAuthSignout() {
  document.dispatchEvent(new CustomEvent('AuthSignout'));
};

export default Api;
