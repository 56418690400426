import {uuid} from 'shared/components/tinymce/tinymce-react/Utils';
import {PériodeDeTemps, PériodeDeTempsType} from './PériodeDeTemps';


 const feries2022: PériodeDeTemps[] = [
  {
    id: uuid('ferie_2022'),
    type: PériodeDeTempsType.JourFérié,
    nom: 'Lundi de Pâques',
    dateDebut: '2022-04-18',
    dateFin: '2022-04-18',
  },
  {
    id: uuid('ferie_2022'),
    type: PériodeDeTempsType.JourFérié,
    nom: 'Fête du Travail',
    dateDebut: '2022-05-01',
    dateFin: '2022-05-01',
  },
  {
    id: uuid('ferie_2022'),
    type: PériodeDeTempsType.JourFérié,
    nom: 'Victoire des alliés',
    dateDebut: '2022-05-08',
    dateFin: '2022-05-08',
  },
  {
    id: uuid('ferie_2022'),
    type: PériodeDeTempsType.JourFérié,
    nom: 'Ascension',
    dateDebut: '2022-05-26',
    dateFin: '2022-05-26',
  },
  {
    id: uuid('ferie_2022'),
    type: PériodeDeTempsType.JourFérié,
    nom: 'Lundi de pentecôte',
    dateDebut: '2022-06-06',
    dateFin: '2022-06-06',
  },
  {
    id: uuid('ferie_2022'),
    type: PériodeDeTempsType.JourFérié,
    nom: 'Fête nationale',
    dateDebut: '2022-07-14',
    dateFin: '2022-07-14',
  },
  {
    id: uuid('ferie_2022'),
    type: PériodeDeTempsType.JourFérié,
    nom: 'Assomption',
    dateDebut: '2022-08-15',
    dateFin: '2022-08-15',
  },
  {
    id: uuid('ferie_2022'),
    type: PériodeDeTempsType.JourFérié,
    nom: 'La Toussaint',
    dateDebut: '2022-11-01',
    dateFin: '2022-11-01',
  },
  {
    id: uuid('ferie_2022'),
    type: PériodeDeTempsType.JourFérié,
    nom: 'Armistice',
    dateDebut: '2022-11-11',
    dateFin: '2022-11-11',
  },
  {
    id: uuid('ferie_2022'),
    type: PériodeDeTempsType.JourFérié,
    nom: 'Noël',
    dateDebut: '2022-12-25',
    dateFin: '2022-12-25',
  },
];
const feries2023: PériodeDeTemps[] = [
  {
    id: uuid('ferie_2023'),
    type: PériodeDeTempsType.JourFérié,
    nom: "Jour de l'an",
    dateDebut: '2023-01-01',
    dateFin: '2023-01-01'
  },
  {
    id: uuid('ferie_2023'),
    type: PériodeDeTempsType.JourFérié,
    nom: 'Lundi de Pâques',
    dateDebut: '2023-04-10',
    dateFin: '2023-04-10',
  },
  {
    id: uuid('ferie_2023'),
    type: PériodeDeTempsType.JourFérié,
    nom: 'Fête du Travail',
    dateDebut: '2023-05-01',
    dateFin: '2023-05-01',
  },
  {
    id: uuid('ferie_2023'),
    type: PériodeDeTempsType.JourFérié,
    nom: 'Victoire des alliés',
    dateDebut: '2023-05-08',
    dateFin: '2023-05-08',
  },
  {
    id: uuid('ferie_2023'),
    type: PériodeDeTempsType.JourFérié,
    nom: "Jeudi de l'ascension",
    dateDebut: '2023-05-18',
    dateFin: '2023-05-18',
  },
  {
    id: uuid('ferie_2023'),
    type: PériodeDeTempsType.JourFérié,
    nom: 'Lundi de pentecôte',
    dateDebut: '2023-05-29',
    dateFin: '2023-05-29',
  },
  {
    id: uuid('ferie_2023'),
    type: PériodeDeTempsType.JourFérié,
    nom: 'Fête nationale',
    dateDebut: '2023-07-14',
    dateFin: '2023-07-14',
  },
  {
    id: uuid('ferie_2023'),
    type: PériodeDeTempsType.JourFérié,
    nom: 'Assomption',
    dateDebut: '2023-08-15',
    dateFin: '2023-08-15',
  },
  {
    id: uuid('ferie_2023'),
    type: PériodeDeTempsType.JourFérié,
    nom: 'La Toussaint',
    dateDebut: '2023-11-01',
    dateFin: '2023-11-01',
  },
  {
    id: uuid('ferie_2023'),
    type: PériodeDeTempsType.JourFérié,
    nom:'Armistice',
    dateDebut: '2023-11-11',
    dateFin: '2023-11-11',
    
  },
  {
    id: uuid('ferie_2023'),
    type: PériodeDeTempsType.JourFérié,
    nom: 'Noël',
    dateDebut: '2023-12-25',
    dateFin: '2023-12-25',
  },
];

const vacances2022: PériodeDeTemps[] = [
  {
    id: uuid('vacances_2022'),
    nom: 'Vacances de printemps',
    dateDebut: '2022-04-23',
    dateFin: '2022-05-08',
    type: PériodeDeTempsType.VacancesScolaires,
  },
  {
    id: uuid('vacances_2022'),
    nom: "Pont de l'Ascension",
    dateDebut: '2022-05-26',
    dateFin: '2022-05-29',
    type: PériodeDeTempsType.VacancesScolaires,
  },
  {
    id: uuid('vacances_2022'),
    nom: 'Fin des cours',
    dateDebut: '2022-07-07',
    dateFin: '2022-09-01',
    type: PériodeDeTempsType.VacancesScolaires,
  },
  {
    id: uuid('vacances_2022'),
    nom: 'Vacances de la Toussaint',
    dateDebut: '2022-10-22',
    dateFin: '2022-11-07',
    type: PériodeDeTempsType.VacancesScolaires,
  },
  {
    id: uuid('vacances_2022'),
    nom: 'Vacances de Noël',
    dateDebut: '2022-12-17',
    dateFin: '2023-01-03',
    type: PériodeDeTempsType.VacancesScolaires,
  },
];
const vacances2023: PériodeDeTemps[] = [
  {
    id: uuid('vacances_2023'),
    nom: 'Vacances d\'hiver',
    dateDebut: '2023-02-18',
    dateFin: '2023-03-06',
    type:PériodeDeTempsType.VacancesScolaires
  },
  {
    id: uuid('vacances_2023'),
    nom: 'Vacances de printemps',
    dateDebut: '2023-04-22',
    dateFin: '2023-05-09',
    type:PériodeDeTempsType.VacancesScolaires
  },
  {
    id: uuid('vacances_2023'),
    nom: 'Fin des cours',
    dateDebut: '2023-07-08',
    dateFin: '2023-09-04',
    type:PériodeDeTempsType.VacancesScolaires
  },
];


const périodeDeTempsDefaultList = [...feries2022, ...feries2023, ...vacances2022, ...vacances2023];
export default périodeDeTempsDefaultList;