import moment from 'moment';

export enum PériodeDeTempsType {
  Aucun = '',
  JourFérié = 'Jour Férié',
  VacancesScolaires = 'Vacances Scolaires'
}

export interface PériodeDeTemps {
  id: string;
  type: PériodeDeTempsType;
  nom: string;
  dateDebut: string | null;
  dateFin: string | null;
}

export function newPériodeDeTemps(): PériodeDeTemps {
  return {
    id: '',
    type: PériodeDeTempsType.JourFérié,
    nom: '',
    dateDebut: null,
    dateFin: null,
  };
}


export const sortPériodeDeTempsByDate = (
  data: PériodeDeTemps[],
): PériodeDeTemps[] =>
  data.sort((a, b) =>
    a.dateDebut! > b.dateDebut! ? 1 : b.dateDebut! > a.dateDebut! ? -1 : 0,
  );

export const sortPériodeDeTempsByDuration = (
  data: PériodeDeTemps[],
): PériodeDeTemps[] => {
  const newData = data.map((d) => {
    const duration = moment(d.dateFin).diff(moment(d.dateDebut), 'days');
    return {
      ...d,
      duration,
    };
  });
  return newData.sort((a, b) => a.duration - b.duration);
};
