import moment from 'moment';
import {BureauRef} from '../admin/Bureau';
import {ExaminateurRef, ExaminateurEtBureauxRef} from '../admin/Utilisateur';
import {readZones, readCréneaux} from '../configuration/Configuration';
import {Secteur} from '../configuration/ConfigurationDefaultList';
//import {colors} from './DisponibilitéRV';
import {DVZone, DVCréneau} from './DisponibilitéDVConfig';
import {DaysOfWeek, getDayOfWeekNumbers, constDaysOfWeekRefs} from './Temps';
import {heureToString, fromNumberOfDaysSince1970, toNumberOfDaysSince1970, fromNumberOfMinutes, toNumberOfMinutes} from '../secretariat/RendezVous';
import {getSelectionBureaux} from 'shared/components/AppLogo';

interface ZoneDVObj {
  id: number;
  nom: string;
  //color: string; // TODO: Not used => Remove?
  jours: DaysOfWeek;
  secteurs: string[];
  bureau: BureauRef;
  créneaux: CréneauDVObj[];
}

export interface ZoneDVRef {
  id: number;
  nom: string;
  jours: DaysOfWeek;
  bureau: BureauRef;
}

export interface CréneauDVObj {
  id: number;
  nom: string;
  dateDebut: string | null; // Un Lundi de préférence
  dateFin: string | null; // Un Dimanche de préférence // if (selectedDate est hors de cet interval) ne pas proposer ce créneau dans de la programmation
  heureDebut: number; // Nombre de minutes depuis minuit (inclusive)
  heureFin: number; // Nombre de minutes depuis minuit (exclusive)
  zone: ZoneDVRef;
  jours: JourDVObj[];
}

interface CréneauDVRef {
  id: number;
  nom: string;
  zone: ZoneDVRef;
}

interface CréneauAbstractRef {
  id: number;
  nom: string;
  zone: {
    id: number;
    nom: string;
  } | null;
}

export function getZoneRefs(): ZoneDVRef[] {
  const dvZones = readZones();
  return dvZones
    .filter(z => z.jours && z.bureau) // Filter out invalid zones (should be impossible)
    .map(z => {
      return {
        id: z.id,
        nom: z.nom,
        jours: z.jours!.id,
        secteurs: z.secteurs,
        bureau: z.bureau!,
        créneaux: [],
      };
    })
    .sort((a, b) => a.nom.localeCompare(b.nom));
}

export function getZones(): ZoneDVObj[] {
  const nextDay = 24 * 60;
  const dvZones = readZones();
  const dvCréneaux = readCréneaux();
  return dvZones
    .filter(z => z.jours && z.bureau) // Filter out invalid zones (should be impossible)
    .map(z => {
      return {
        id: z.id,
        nom: z.nom,
        jours: z.jours!.id,
        secteurs: z.secteurs,
        bureau: z.bureau!,
        créneaux: dvCréneaux
          .filter(c => c.zone?.id === z.id && c.heureDebut !== null && c.heureFin !== null) // Filter by zone & fitler out invalid créneaux (should be impossible)
          .map(c => {
            return {
              id: c.id,
              nom: c.nom,
              dateDebut: c.dateDebut,
              dateFin: c.dateFin,
              heureDebut: toNumberOfMinutes(c.heureDebut)!,
              heureFin: toNumberOfMinutes(c.heureFin)! + (c.heureDebut! >= c.heureFin! ? nextDay : 0), // Account for nextDay
              zone: {
                id: z.id,
                nom: z.nom,
                jours: z.jours!.id,
                bureau: z.bureau!
              },
              jours: [],
            };
          }),
      };
    })
    .sort((a, b) => a.nom.localeCompare(b.nom));
}

export function getZoneAndCréneauDefaultLists(bureauList: BureauRef[]) : {dvZones: DVZone[], dvCréneaux: DVCréneau[]} {
  const zones = getZoneDefaultList(bureauList);
  const dvZones: DVZone[] = [];
  const dvCréneaux: DVCréneau[] = [];
  for (const z of zones) {
    dvZones.push({
      id: z.id,
      nom: z.nom,
      jours: Object.values(constDaysOfWeekRefs).find(d => d.id === z.jours)!,
      secteurs: z.secteurs,
      bureau: z.bureau,
      notes: '',
    });
    for (const c of z.créneaux) {
      dvCréneaux.push({
        id: c.id,
        nom: c.nom,
        dateDebut: c.dateDebut,
        dateFin: c.dateFin,
        heureDebut: fromNumberOfMinutes(c.heureDebut)!.format('YYYY-MM-DD HH:mm'),
        heureFin: fromNumberOfMinutes(c.heureFin)!.format('YYYY-MM-DD HH:mm'),
        zone: {
          id: z.id,
          nom: z.nom,
        },
        notes: '',
      });
    }
  }
  return {dvZones, dvCréneaux};
}

function getZoneDefaultList(bureauList: BureauRef[]) {
  const bureauArgenteuil = bureauList.find(b => b.nom === 'Argenteuil');
  const bureauChartres = bureauList.find(b => b.nom === 'Chartres');
  const bureauParisNord = bureauList.find(b => b.nom === 'Paris Nord');

  if (!bureauArgenteuil || !bureauChartres || !bureauParisNord) return []; // Happens once as the page is loading

  const zDépôt: ZoneDVObj = {
    id: 10,
    nom: 'Dépôt',
    //color: colors[0],
    jours: DaysOfWeek.All,
    secteurs: [Secteur.sdpo],
    bureau: bureauArgenteuil,
    créneaux: [],
  };
  const z78: ZoneDVObj = {
    id: 20,
    nom: '78',
    //color: colors[2],
    jours: DaysOfWeek.All,
    secteurs: [Secteur.s78],
    bureau: bureauArgenteuil,
    créneaux: [],
  };
  const z93: ZoneDVObj = {
    id: 30,
    nom: '93',
    //color: colors[7],
    jours: DaysOfWeek.All,
    secteurs: [Secteur.s93],
    bureau: bureauArgenteuil,
    créneaux: [],
  };
  const z95: ZoneDVObj = {
    id: 35,
    nom: '95',
    //color: colors[1],
    jours: DaysOfWeek.All,
    secteurs: [Secteur.s95],
    bureau: bureauArgenteuil,
    créneaux: [],
  };
  const zPoissy: ZoneDVObj = {
    id: 40,
    nom: 'Poissy',
    //color: colors[6],
    jours: DaysOfWeek.All,
    secteurs: [Secteur.sPoi],
    bureau: bureauArgenteuil,
    créneaux: [],
  };
  const z92DD: ZoneDVObj = {
    id: 50,
    nom: '92 + DD',
    //color: colors[4],
    jours: DaysOfWeek.All,
    secteurs: [Secteur.s92, Secteur.sDD],
    bureau: bureauArgenteuil,
    créneaux: [],
  };
  const zChartres: ZoneDVObj = {
    id: 60,
    nom: 'Chartres',
    //color: colors[8],
    jours: DaysOfWeek.Tuesday,
    secteurs: [], // TODO: Any?
    bureau: bureauChartres,
    créneaux: [],
  };
  const zSecteurNord: ZoneDVObj = {
    id: 70,
    nom: 'Secteur Nord',
    //color: colors[9],
    jours: DaysOfWeek.All,
    secteurs: [Secteur.sNord],
    bureau: bureauParisNord,
    créneaux: [],
  };
  const zSecteurSud: ZoneDVObj = {
    id: 80,
    nom: 'Secteur Sud',
    //color: colors[10],
    jours: DaysOfWeek.All,
    secteurs: [Secteur.sSud],
    bureau: bureauParisNord,
    créneaux: [],
  };
  const nextDay = 24 * 60;
  zDépôt.créneaux.push(
    { id: 110, nom: 'Matin', dateDebut: null, dateFin: null, heureDebut: 8 * 60, heureFin: 12 * 60, zone: zDépôt, jours: [], },
    { id: 120, nom: 'Après-midi', dateDebut: null, dateFin: null, heureDebut: 14 * 60, heureFin: 16 * 60, zone: zDépôt, jours: [], },
    { id: 130, nom: 'Soir', dateDebut: null, dateFin: null, heureDebut: 20 * 60, heureFin: 24 * 60, zone: zDépôt, jours: [], },
  );
  z78.créneaux.push(
    { id: 140, nom: 'Matin', dateDebut: null, dateFin: null, heureDebut: 8 * 60, heureFin: 12 * 60, zone: z78, jours: [], },
    { id: 150, nom: 'Soir', dateDebut: null, dateFin: null, heureDebut: 19 * 60, heureFin: 24 * 60, zone: z78, jours: [], },
  );
  z93.créneaux.push(
    { id: 160, nom: 'Matin', dateDebut: null, dateFin: null, heureDebut: 8 * 60, heureFin: 14 * 60, zone: z93, jours: [], },
    { id: 170, nom: 'Après-midi', dateDebut: null, dateFin: null, heureDebut: 14 * 60, heureFin: 19 * 60, zone: z93, jours: [], },
    { id: 180, nom: 'Soir', dateDebut: null, dateFin: null, heureDebut: 19 * 60, heureFin: 8 * 60 + nextDay, zone: z93, jours: [], },
  );
  z95.créneaux.push(
    { id: 184, nom: 'Matin', dateDebut: null, dateFin: null, heureDebut: 8 * 60, heureFin: 12 * 60, zone: z95, jours: [], },
    { id: 185, nom: 'Après-midi', dateDebut: null, dateFin: null, heureDebut: 12 * 60, heureFin: 16 * 60, zone: z95, jours: [], },
    { id: 186, nom: 'Soir', dateDebut: null, dateFin: null, heureDebut: 16 * 60, heureFin: 24 * 60, zone: z95, jours: [], },
  );
  zPoissy.créneaux.push(
    { id: 190, nom: 'Matin', dateDebut: null, dateFin: null, heureDebut: 10 * 60, heureFin: 12 * 60, zone: zPoissy, jours: [], },
    { id: 200, nom: 'Soir', dateDebut: null, dateFin: null, heureDebut: 20 * 60, heureFin: 22 * 60, zone: zPoissy, jours: [], },
  );
  z92DD.créneaux.push(
    { id: 210, nom: 'Matin', dateDebut: null, dateFin: null, heureDebut: 9 * 60, heureFin: 12 * 60, zone: z92DD, jours: [], },
    { id: 220, nom: 'Après-midi', dateDebut: null, dateFin: null, heureDebut: 12 * 60, heureFin: 15 * 60, zone: z92DD, jours: [], },
    { id: 230, nom: 'Soir', dateDebut: null, dateFin: null, heureDebut: 19 * 60, heureFin: 24 * 60, zone: z92DD, jours: [], },
  );
  zChartres.créneaux.push(
    { id: 240, nom: 'Matin', dateDebut: null, dateFin: null, heureDebut: 10 * 60, heureFin: 13 * 60, zone: zChartres, jours: [], },
    { id: 250, nom: 'Après-midi', dateDebut: null, dateFin: null, heureDebut: 14 * 60, heureFin: 16 * 60, zone: zChartres, jours: [], },
  );
  zSecteurNord.créneaux.push(
    { id: 260, nom: 'Matin', dateDebut: null, dateFin: null, heureDebut: 8 * 60, heureFin: 14 * 60, zone: zSecteurNord, jours: [], },
    { id: 270, nom: 'Après-midi', dateDebut: null, dateFin: null, heureDebut: 14 * 60, heureFin: 20 * 60, zone: zSecteurNord, jours: [], },
    { id: 280, nom: 'Soir', dateDebut: null, dateFin: null, heureDebut: 20 * 60, heureFin: 8 * 60 + nextDay, zone: zSecteurNord, jours: [], },
  );
  zSecteurSud.créneaux.push(
    { id: 290, nom: 'Matin', dateDebut: null, dateFin: null, heureDebut: 8 * 60, heureFin: 14 * 60, zone: zSecteurSud, jours: [], },
    { id: 300, nom: 'Après-midi', dateDebut: null, dateFin: null, heureDebut: 14 * 60, heureFin: 20 * 60, zone: zSecteurSud, jours: [], },
    { id: 310, nom: 'Soir', dateDebut: null, dateFin: null, heureDebut: 20 * 60, heureFin: 8 * 60 + nextDay, zone: zSecteurSud, jours: [], },
  );
  const zones: ZoneDVObj[] = [ zDépôt, z78, z93, z95, zPoissy, z92DD, zChartres, zSecteurNord, zSecteurSud ];
  return zones;
};

export function getCréneaux(zones: ZoneDVObj[]) {
  const créneaux: CréneauDVObj[] = [];
  for (const zone of zones)
      créneaux.push(...zone.créneaux);
  return créneaux;
}

export function getCréneau(créneaux: CréneauDVObj[], id?: number) {
  if (!id) return undefined;
  return créneaux.find(c => c.id === id);
}

export function getCréneauSlow(id?: number) {
  if (!id) return undefined;
  return getCréneau(getCréneaux(getZones()), id);
}

export function getCréneauNomComplet(créneau?: CréneauAbstractRef) {
  return créneau ? (créneau.zone?.nom ?? ' ?') + ' ' + créneau.nom : '?';
}

export function getCréneauHeureFin(prefix: string, créneaux: CréneauDVObj[], id?: number, rendezVousHeure?: number) {
  const créneau = créneaux.find(c => c.id === id);
  if (!créneau) return '';
  const nextDay = 24 * 60;
  const heureFin = créneau.heureFin >= nextDay ? (créneau.heureDebut === rendezVousHeure ? 0 : /*0 === rendezVousHeure ?*/ créneau.heureFin) : créneau.heureFin; // Account for nextDay
  return prefix + heureToString(heureFin);
}



export function filterByCréneauId<T extends {secteur: string}>(objects: T[], zones: ZoneDVObj[], créneaux: CréneauDVObj[], id?: number | null) : T[] {
  if (!id) return objects; // DossierPatient is not a DemandeDeVisite
  const créneau = getCréneau(créneaux, id);
  if (!créneau) return objects; // Créneau is not found. Unlikely, but can happen if deleted.
  const zone = zones.find(z => z.id === créneau.zone.id);
  if (!zone) return objects; // Should be impossible. Unlikely, but can happen if deleted.
  if (zone.secteurs.length === 0) return objects; // Zone is not restricted
  return objects.filter(o => zone.secteurs.includes(o.secteur));
}













export interface JourDVObj {
  date: number; // Nombre de jours depuis 1970 // Note: Must be a day selected in "créneau.zone.jours: DaysOfWeek"
  créneau: CréneauDVRef;
  disponibilités: DisponibilitéDVObj[];
}

export function isPasDeSéance(jour: JourDVObj) : boolean {
  const moment = fromNumberOfDaysSince1970(jour.date);
  if (!moment) return true; // Should never happen
  const dayOfWeekNumbers = getDayOfWeekNumbers(jour.créneau.zone.jours);
  return !dayOfWeekNumbers.includes(8) && !dayOfWeekNumbers.includes(moment.isoWeekday()); // Not All && Not This Day
}

export interface DisponibilitéDVObj {
  id: number;
  créneauId: number;
  date: number; // Nombre de jours depuis 1970
  examinateur: ExaminateurRef;
  choixExaminateur: Choix; // Note: If set to 'Non Disponible', we can delete this obj
  choixSecretariat: Choix;
}

export function getChoixActuel(dispo: DisponibilitéDVObj) {
  if (dispo.choixExaminateur.id === choix.GardeFinie.id) return dispo.choixExaminateur;
  return dispo.choixSecretariat.id === choix.ConfirméProvisoire.id || dispo.choixSecretariat.id === choix.ConfirméDéfinitif.id ? dispo.choixSecretariat : dispo.choixExaminateur;
}

export interface Choix {
  id: number;
  nom: string;
}
export const choix = {
  PasDeSéance: { id: 0, nom: 'Pas de Séance' },
  NonDisponible: { id: 10, nom: 'Non Disponible' },
  Disponible: { id: 20, nom: 'Disponible' },
  Souhaité: { id: 30, nom: 'Souhaité' },
  ConfirméProvisoire: { id: 40, nom: 'Confirmé ?' },
  ConfirméDéfinitif: { id: 50, nom: 'Confirmé !' },
  GardeFinie: { id: 70, nom: 'Garde Finie' },
};

const constChoixListe: Choix[] = [
  choix.PasDeSéance, choix.NonDisponible, choix.Disponible, choix.Souhaité, choix.ConfirméProvisoire, choix.ConfirméDéfinitif, choix.GardeFinie,
];
export const constChoixExaminateur: Choix[] = [
  choix.NonDisponible, choix.Disponible, choix.Souhaité,
];
export const constChoixExaminateurGardeFinie: Choix[] = [
  choix.Disponible, choix.Souhaité, choix.GardeFinie,
];

export function getChoix(choixId: number) : Choix {
  return constChoixListe.find(c => c.id === choixId) ?? choix.PasDeSéance;
}

export function getCanEditChoixExaminateur(dispo: DisponibilitéDVObj) {
  const choixActuel = getChoixActuel(dispo);
  if (constChoixExaminateur.find(c => c.id === choixActuel.id) || choixActuel.id === choix.GardeFinie.id) return true;

  if (choixActuel.id !== choix.ConfirméDéfinitif.id) return false;
  // Can 'Mettre fin à la garde' if the dispo is ongoing? For now, we check if it is for today or tomorrow. // TODO: Better solution?
  const today = toNumberOfDaysSince1970(moment().format('YYYY-MM-DD'))!;
  const diff = dispo.date - today;
  return 0 <= diff && diff <= 1;
}





















export function getCréneauxEnCours(zones: ZoneDVObj[], listeDisponibilitéDV: DisponibilitéDVObj[], examinateurSelect: ExaminateurEtBureauxRef | null, date: number, isSingleDay: boolean) {
  const selection = getSelectionBureaux();

  const objets = listeDisponibilitéDV;

  const créneaux: CréneauDVObj[] = [];
  for (const zone of zones) {
    if (!selection.bureaux.find(b => b.id === zone.bureau.id))
      continue; // AuthUser cannot access this zone
    if (examinateurSelect && !examinateurSelect.bureaux.find(b => b.id === zone.bureau.id))
      continue; // Selected examinateur cannot be assigned to this zone

    for (const créneauOr of zone.créneaux) {
      const créneau: CréneauDVObj = {...créneauOr, jours: []}; // Clone to avoid changing the stored state
      for (let jour = date; jour < date + (isSingleDay ? 1 : 7); jour++) {
        const jourObj: JourDVObj = {
          date: jour,
          créneau: créneau,
          disponibilités: [],
         };
        const matches = objets.filter(objet => objet.créneauId === créneau.id && objet.date === jour);
        if (examinateurSelect) {
          if (matches.length > 1) // Should be impossible
            matches.length = 0; // Clear it so the cell stays in a "bad" state (require manually deleting the duplicate)
          else if (matches.length < 1) // None, so add the default dispo
            matches.push({
              id: 0,
              créneauId: jourObj.créneau.id,
              date: jourObj.date,
              examinateur: examinateurSelect,
              choixExaminateur: choix.NonDisponible,
              choixSecretariat: choix.NonDisponible,
            });
        }
        jourObj.disponibilités.push(...matches);
        créneau.jours.push(jourObj);
      }
      créneaux.push(créneau);
    }
  }

  créneaux.sort(compareCréneaux);
  return créneaux;
};

function getOrder(c: CréneauDVObj) : string {
  if (c.nom === 'Matin') return '1_' + c.nom;
  if (c.nom === 'Après-midi') return '2_' + c.nom;
  if (c.nom === 'Soir') return '3_' + c.nom;
  return '4_' + c.nom;
};

function compareCréneaux(a: CréneauDVObj, b: CréneauDVObj) : number {
  const aO = getOrder(a);
  const bO = getOrder(b);
  if (aO === bO)
    return a.zone.nom.localeCompare(b.zone.nom);
  return aO.localeCompare(bO);
}
