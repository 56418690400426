import Api from '@crema/services/ApiConfig';
import {PériodeDeTemps} from './PériodeDeTemps';
import {TypeExamen} from '../secretariat/DossierPatient';
import {DVZone, DVCréneau} from '../secretariat/DisponibilitéDVConfig';

export interface Réglages {
  montantTVA: number;
  tauxDeParticipationAuxFrais: number;
  secteurs: string[];
  notes: string;
}

export interface RéglagesEditable {
  montantTVA: number | string;
  tauxDeParticipationAuxFrais: number | string;
  secteurs: string;
  notes: string;
}

export function fromRéglagesEditable(objet: RéglagesEditable) : Réglages {
  if (typeof objet.montantTVA !== 'number')
    throw new Error('Réglages: TVA invalide');
  if (typeof objet.tauxDeParticipationAuxFrais !== 'number')
    throw new Error('Réglages: Taux de participation aux frais invalide');
  return {
    montantTVA: objet.montantTVA,
    tauxDeParticipationAuxFrais: objet.tauxDeParticipationAuxFrais,
    secteurs: objet.secteurs ? objet.secteurs.split(/[\r\n]+/) : [],
    notes: objet.notes,
  };
}

export function toRéglagesEditable(objet: Réglages) : RéglagesEditable {
  return {
    montantTVA: objet.montantTVA,
    tauxDeParticipationAuxFrais: objet.tauxDeParticipationAuxFrais,
    secteurs: objet.secteurs.length > 0 ? objet.secteurs.reduce((s1, s2) => s1 + '\n' + s2) : '',
    notes: objet.notes,
  };
}

export enum Clé {
  périodesDeTemps = 'périodesDeTemps',
  zones = 'zones',
  créneaux = 'créneaux',
  typesDExamen = 'typesDExamen',
  réglages = 'réglages',
}

export interface ConfigurationObj {
  clé: Clé;
  valeur: any;
}

export interface ConfigurationList {
  périodesDeTemps: PériodeDeTemps[];
  zones: DVZone[];
  créneaux: DVCréneau[];
  typesDExamen: TypeExamen[];
  réglages: Réglages;
}



export async function getConfigurationListFromApi(): Promise<ConfigurationList | null> {
  const response = await Api.get('/configuration/');
  const list = response.data as ConfigurationObj[];
  const obj: ConfigurationList = {
    périodesDeTemps: list.find(c => c.clé === Clé.périodesDeTemps)?.valeur,
    zones: list.find(c => c.clé === Clé.zones)?.valeur,
    créneaux: list.find(c => c.clé === Clé.créneaux)?.valeur,
    typesDExamen: list.find(c => c.clé === Clé.typesDExamen)?.valeur,
    réglages: list.find(c => c.clé === Clé.réglages)?.valeur,
  };
  if (list.length === 0) { // Empty => Needs to seed
    return null;
  }
  if (list.length !== Object.values(Clé).length || !obj.périodesDeTemps || !obj.zones || !obj.créneaux || !obj.typesDExamen || !obj.réglages) {
    console.log('Configuration Invalide', list);
    throw new Error('Configuration Invalide');
  }
  return obj;
};

export async function createConfigurationInApi(objet: ConfigurationObj) {
  const response = await Api.post('/configuration/', objet);
  const configuration = response.data as ConfigurationObj;
  return configuration;
};



function readConfigurationList(): ConfigurationList | undefined {
  const value = localStorage.getItem('configuration');
  return value ? (JSON.parse(value) as ConfigurationList) : undefined;
};

async function updateConfiguration(objet: ConfigurationObj) {
  if (!window.confirm('Attention : Les configurations doivent être correctes pour assurer le bon fonctionnement de cette application.\n\nÊtes-vous sûr de vouloir continuer ?'))
    return;
  const response = await Api.patch('/configuration/' + encodeURIComponent(objet.clé), objet);
  const configuration = response.data as ConfigurationObj;
  const configurationList = readConfigurationList();
  if (!configuration || !configurationList) throw new Error('Configuration Unavailable');
  configurationList[configuration.clé] = configuration.valeur;
  localStorage.setItem('configuration', JSON.stringify(configurationList));
};

export function readPériodesDeTemps(): PériodeDeTemps[] {
  return readConfigurationList()?.périodesDeTemps ?? [];
}

export async function updatePériodesDeTemps(objet: PériodeDeTemps[]) {
  return updateConfiguration({clé: Clé.périodesDeTemps, valeur: objet});
};

export function readZones(): DVZone[] {
  return readConfigurationList()?.zones ?? [];
}

export async function updateZones(objet: DVZone[]) {
  return updateConfiguration({clé: Clé.zones, valeur: objet});
};

export function readCréneaux(): DVCréneau[] {
  return readConfigurationList()?.créneaux ?? [];
}

export async function updateCréneaux(objet: DVCréneau[]) {
  return updateConfiguration({clé: Clé.créneaux, valeur: objet});
};

export function readTypesDExamen(): TypeExamen[] {
  return readConfigurationList()?.typesDExamen ?? [];
}

export async function updateTypesDExamen(objet: TypeExamen[]) {
  return updateConfiguration({clé: Clé.typesDExamen, valeur: objet});
};

export function readRéglages(): Réglages {
  const r = readConfigurationList()?.réglages;
  if (!r?.montantTVA) throw new Error('Réglages invalides');
  return r;
}

export async function updateRéglages(objet: Réglages) {
  return updateConfiguration({clé: Clé.réglages, valeur: objet});
};
