import {useDispatch, useSelector} from 'react-redux';
import {useEffect, useState} from 'react';
import {fetchStart, fetchSuccess, loadJWTUser, setJWTToken} from '../../redux/actions';
import {authRole} from '../../shared/constants/AppConst';
import {AppState} from '../../redux/store';
import {AuthUser} from '../../types/models/AuthUser';
import {checkPermission} from './Utils';

export const useAuthToken = (): [boolean, AuthUser | null] => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const {user} = useSelector<AppState, AppState['auth']>(({auth}) => auth);

  useEffect(() => {
    const validateAuth = async () => {
      dispatch(fetchStart());
      const token = localStorage.getItem('token');
      if (!token) {
        dispatch(fetchSuccess());
        return;
      }
      dispatch(setJWTToken(token));
      await loadJWTUser(dispatch);
    };

    const checkAuth = () => {
      Promise.all([validateAuth()]).then(() => {
        setLoading(false);
      });
    };
    checkAuth();
  }, [dispatch]);

  return [loading, user];
};

export const useAuthUser = (): AuthUser | null => {
  const {user} = useSelector<AppState, AppState['auth']>(({auth}) => auth);
  return user;
};

const DoesNotHaveRole = (role: string[]): boolean => { // TODO: Not sure why only this function must start with an uppercase letter to avoid error: 'React Hook "useSelector" is called in function "DoesNotHaveRole" that is neither a React function component nor a custom React Hook function. React component names must start with an uppercase letter.'
  const {user} = useSelector<AppState, AppState['auth']>(({auth}) => auth);
  return userDoesNotHaveRole(user, role);
};

const userDoesNotHaveRole = (user: AuthUser | null, role: string[]): boolean => {
  if (user) {
    return !checkPermission(role, user.role);
  }
  return true;
};

export const cannotEditAdmin = (): boolean => {
  return DoesNotHaveRole(authRole.admin);
};

export const cannotUseAdminFeatures = (): boolean => {
  return DoesNotHaveRole(authRole.admin);
};

export const cannotEditConfiguration = (): boolean => {
  return DoesNotHaveRole(authRole.admin);
};

export const cannotEditGlobal = (): boolean => {
  return DoesNotHaveRole(authRole.secretariat);
};

export const cannotEditBordereau = (): boolean => {
  return DoesNotHaveRole(authRole.secretariat);
};

export const doesNotHaveSecretariatRole = (): boolean => {
  return DoesNotHaveRole(authRole.anySecretariat);
};

export const cannotUseAdvancedFeatures = (): boolean => {
  return DoesNotHaveRole(authRole.secretariat);
};

export const canEditChildObjet = (user: AuthUser | null, objetOwnerId?: string | null, medecinCannotEditOwnerless?: boolean | null): boolean => {
  if (!user) return false; // Should be impossible
  if (!userDoesNotHaveRole(user, authRole.secretariat)) return true;
  if (!objetOwnerId && medecinCannotEditOwnerless && !userDoesNotHaveRole(user, authRole.medecin)) return false;
  if (!objetOwnerId) return true;
  return user?.id === objetOwnerId;
};
