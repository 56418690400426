import {DPRapportObj} from '../models/configuration/DPRapport';

export const CREATE_NEW_DPRAPPORT = 'CREATE_NEW_DPRAPPORT';
export const DELETE_DPRAPPORT = 'DELETE_DPRAPPORT';
export const GET_DPRAPPORT_LIST = 'GET_DPRAPPORT_LIST';
export const UPDATE_DPRAPPORT_DETAIL = 'UPDATE_DPRAPPORT_DETAIL';

export interface GetObjetsActions {
  type: typeof GET_DPRAPPORT_LIST;
  payload: {list: DPRapportObj[]; total: number};
}

export interface UpdateObjetActions {
  type: typeof UPDATE_DPRAPPORT_DETAIL;
  payload: DPRapportObj;
}

export interface CreateObjetActions {
  type: typeof CREATE_NEW_DPRAPPORT;
  payload: DPRapportObj;
}

export interface DeleteObjetActions {
  type: typeof DELETE_DPRAPPORT;
  payload: {list: DPRapportObj[]; total: number};
}

export type DPRapportActions =
  | GetObjetsActions
  | UpdateObjetActions
  | CreateObjetActions
  | DeleteObjetActions;
