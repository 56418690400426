
export enum DaysOfWeek {
  //None = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 4,
  Thursday = 8,
  Friday = 16,
  Saturday = 32,
  Sunday = 64,
  All = 127,
}

export interface DaysOfWeekRef {
  id: DaysOfWeek;
  nom: string;
}

export function getDayOfWeekNumbers(daysOfWeek: DaysOfWeek): number[] {
  const list: number[] = [];

  if (!daysOfWeek) {
    list.push(0);
    return list;
  }
  if (daysOfWeek === DaysOfWeek.All) {
    list.push(8);
    return list;
  }
  if (daysOfWeek & DaysOfWeek.Monday) list.push(1);
  if (daysOfWeek & DaysOfWeek.Tuesday) list.push(2);
  if (daysOfWeek & DaysOfWeek.Wednesday) list.push(3);
  if (daysOfWeek & DaysOfWeek.Thursday) list.push(4);
  if (daysOfWeek & DaysOfWeek.Friday) list.push(5);
  if (daysOfWeek & DaysOfWeek.Saturday) list.push(6);
  if (daysOfWeek & DaysOfWeek.Sunday) list.push(7);

  if (list.length === 0) list.push(0); // Should be impossible // TODO: Warning?

  return list;
}

export function getDayOfWeekNames(daysOfWeek: DaysOfWeek): string[] {
  return getDayOfWeekNumbers(daysOfWeek).map(day => constDayOfWeekFullName[day]);
}

const constDayOfWeekFullName = [ /*'Aucun',*/ 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche', 'Tous' ];

export const constDaysOfWeekRefs = {
  //None: { id: DaysOfWeek.None, nom: 'Aucun' } as DaysOfWeekRef,
  Monday: { id: DaysOfWeek.Monday, nom: 'Lundi' } as DaysOfWeekRef,
  Tuesday: { id: DaysOfWeek.Tuesday, nom: 'Mardi' } as DaysOfWeekRef,
  Wednesday: { id: DaysOfWeek.Wednesday, nom: 'Mercredi' } as DaysOfWeekRef,
  Thursday: { id: DaysOfWeek.Thursday, nom: 'Jeudi' } as DaysOfWeekRef,
  Friday: { id: DaysOfWeek.Friday, nom: 'Vendredi' } as DaysOfWeekRef,
  Saturday: { id: DaysOfWeek.Saturday, nom: 'Samedi' } as DaysOfWeekRef,
  Sunday: { id: DaysOfWeek.Sunday, nom: 'Dimanche' } as DaysOfWeekRef,
  All: { id: DaysOfWeek.All, nom: 'Tous' } as DaysOfWeekRef,
}
