import {DisponibilitéDVObj} from '../models/secretariat/DisponibilitéDV';

export const CREATE_NEW_DISPONIBILITÉDV = 'CREATE_NEW_DISPONIBILITÉDV';
export const DELETE_DISPONIBILITÉDV = 'DELETE_DISPONIBILITÉDV';
export const GET_DISPONIBILITÉDV_LIST = 'GET_DISPONIBILITÉDV_LIST';
export const UPDATE_DISPONIBILITÉDV_DETAIL = 'UPDATE_DISPONIBILITÉDV_DETAIL';

export interface GetObjetsActions {
  type: typeof GET_DISPONIBILITÉDV_LIST;
  payload: {list: DisponibilitéDVObj[]; total: number};
}

export interface UpdateObjetActions {
  type: typeof UPDATE_DISPONIBILITÉDV_DETAIL;
  payload: DisponibilitéDVObj;
}

export interface CreateObjetActions {
  type: typeof CREATE_NEW_DISPONIBILITÉDV;
  payload: DisponibilitéDVObj;
}

export interface DeleteObjetActions {
  type: typeof DELETE_DISPONIBILITÉDV;
  payload: {list: DisponibilitéDVObj[]; total: number};
}

export type DisponibilitéDVActions =
  | GetObjetsActions
  | UpdateObjetActions
  | CreateObjetActions
  | DeleteObjetActions;
