import React from 'react';
import {authRole} from "shared/constants/AppConst";

export const samplePagesConfig = [
  {
    auth: authRole.any,
    routes: [
      {
        path: '/sample/page-1',
        component: React.lazy(() => import('./Pages/PageOne')),
      },
    ],
  },
  {
    auth: authRole.anySecretariat,
    routes: [
      {
        path: '/sample/page-2',
        component: React.lazy(() => import('./Pages/PageTwo')),
      },
    ],
  },
  {
    auth: authRole.anyExaminateur,
    routes: [
      {
        path: '/sample/page-3',
        component: React.lazy(() => import('./Pages/PageThree')),
      },
    ],
  }
];
