import Settings from './Setting';
import CommonReducer from './CommonReducer';
import Auth from './Auth';

import CommissariatApp from './CommissariatApp';
import RequerantApp from './RequerantApp';
import DPRapportApp from './DPRapportApp';
import UtilisateurApp from './UtilisateurApp';
import BureauApp from './BureauApp';
import DisponibilitéDVApp from './DisponibilitéDVApp';
import DisponibilitéRVApp from './DisponibilitéRVApp';
import DossierPatientApp from './DossierPatientApp';
import BordereauApp from './BordereauApp';
import RépartitionPaiementApp from './RépartitionPaiementApp';

const reducers = {
  settings: Settings,
  auth: Auth,
  common: CommonReducer,
  commissariatApp: CommissariatApp,
  requerantApp: RequerantApp,
  dprapportApp: DPRapportApp,
  utilisateurApp: UtilisateurApp,
  bureauApp: BureauApp,
  disponibilitéDVApp: DisponibilitéDVApp,
  disponibilitéRVApp: DisponibilitéRVApp,
  dossierPatientApp: DossierPatientApp,
  bordereauApp: BordereauApp,
  répartitionPaiementApp: RépartitionPaiementApp,
};

export default reducers;
