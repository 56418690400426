import React from 'react';
import {Redirect} from 'react-router-dom';
import {authRole} from '../../shared/constants/AppConst';

export const globalConfig = [
  {
    auth: authRole.any,
    routes: [
      {
        path: ['/global/commissariat/liste'],
        component: React.lazy(() => import('./Commissariat')),
      },
      {
        path: '/global/commissariat',
        component: () => <Redirect to='/global/commissariat/liste' />,
      },
      {
        path: ['/global/requerant/liste'],
        component: React.lazy(() => import('./Requerant/Liste')),
      },
      {
        path: '/global/requerant',
        component: () => <Redirect to='/global/requerant/liste' />,
      },
      {
        path: ['/global/examinateur/statut/:name', '/global/examinateur/role/:name'],
        component: React.lazy(() => import('../admin/Utilisateur')),
      },
      {
        path: '/global/examinateur',
        component: () => <Redirect to='/global/examinateur/statut/tous' />,
      },
      {
        path: ['/global/documentsvierges/liste'],
        component: React.lazy(() => import('./DocumentVierge/Liste')),
      },
      {
        path: '/global/documentsvierges',
        component: () => <Redirect to='/global/documentsvierges/liste' />,
      },
    ],
  },
];
