import {
  RépartitionPaiementActions,
  CREATE_NEW_REPARTITIONPAIEMENT,
  DELETE_REPARTITIONPAIEMENT,
  GET_REPARTITIONPAIEMENT_LIST,
  UPDATE_REPARTITIONPAIEMENT_DETAIL,
} from '../../types/actions/RépartitionPaiement.actions';
import {RépartitionPaiementObj} from '../../types/models/secretariat/RépartitionPaiement';

const initialState: {
  objetList: RépartitionPaiementObj[];
  totalObjets: number;
  selectedObjet: RépartitionPaiementObj | null;
} = {
  objetList: [],
  totalObjets: 0,
  selectedObjet: null,
};

const RépartitionPaiementApp = (state = initialState, action: RépartitionPaiementActions) => {
  switch (action.type) {
    case GET_REPARTITIONPAIEMENT_LIST:
      return {
        ...state,
        objetList: action.payload.list,
        totalObjets: action.payload.total,
      };

    case CREATE_NEW_REPARTITIONPAIEMENT: // Identical to UPDATE because we replace the one that was statut === Nouveau
      return {
        ...state,
        objetList: state.objetList.map(objet =>
          objet.bureau.id === action.payload.bureau.id && objet.date === action.payload.date ? action.payload : objet,
        ),
      };

    case DELETE_REPARTITIONPAIEMENT: {
      return {
        ...state,
        objetList: action.payload.list,
        totalObjets: action.payload.total,
      };
    }

    case UPDATE_REPARTITIONPAIEMENT_DETAIL:
      return {
        ...state,
        selectedObjet: action.payload,
        objetList: state.objetList.map(objet =>
          objet.bureau.id === action.payload.bureau.id && objet.date === action.payload.date ? action.payload : objet,
        ),
      };

    default:
      return state;
  }
};
export default RépartitionPaiementApp;
