import React, {ReactNode, useContext, useEffect} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {matchRoutes} from 'react-router-config';
import qs from 'qs';
import AppContext from './AppContext';
import {useAuthToken} from './AppHooks';
import {Loader} from '../index';
import {checkPermission} from './Utils';
import {initialUrl} from 'shared/constants/AppConst';
import {setInitialPath} from 'redux/actions';
import {AppState} from 'redux/store';
import AppContextPropsType from 'types/AppContextPropsType';
import {NavStyle, ThemeMode, ThemeStyle} from 'shared/constants/AppEnums';
import {authRole} from 'shared/constants/AppConst';
import {onJWTAuthSignout} from 'redux/actions';
import {loadConfigurationList} from 'types/models/configuration/ConfigurationDefaultList';

interface AuthRoutesProps {
  children: ReactNode;
}

const AuthRoutes: React.FC<AuthRoutesProps> = ({children}) => {
  const {pathname, search} = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    routes,
    changeNavStyle,
    updateThemeStyle,
    updateThemeMode,
    setRTL,
  } = useContext<AppContextPropsType>(AppContext);

  const token = localStorage.getItem('token');
  const [loading, user] = useAuthToken();
  const {initialPath} = useSelector<AppState, AppState['settings']>(
    ({settings}) => settings,
  );
  const currentRoute = matchRoutes(routes, pathname)[0].route;
  let isPermitted = checkPermission(currentRoute.auth, user ? user.role : null);



  const onAuthSignout = React.useCallback(() => {
    dispatch(onJWTAuthSignout());
  }, [dispatch]);

  useEffect(() => {
    document.addEventListener('AuthSignout', onAuthSignout);
    return () => {
      document.removeEventListener('AuthSignout', onAuthSignout);
    }
  });

  useEffect(() => {
    //if (error === 'Unauthorized' && window.location.pathname !== '/acceuil') { // OLD version
    if (!token && user) { // Auto-signout the user when their token expires (error === 'Unauthorized') // TODO: Hack-y to put it here, but faster since I only need to put it in one place
      onAuthSignout();
    }
  }, [onAuthSignout, token, user]);

  async function onModificationTableConfiguration() {
    await loadConfigurationList();
    window.alert('Attention : L\'application a été mise à jour.\nMerci de rafraichir votre page.\nA défaut, elle sera rafraichie dans 30 secondes.');
    setTimeout(
      function() {
        window.location.reload(); // Refresh any potential useState()
      }, 30000);
  }

  useEffect(() => {
    document.addEventListener('ModificationTableConfiguration', onModificationTableConfiguration);
    return () => {
      document.removeEventListener('ModificationTableConfiguration', onModificationTableConfiguration);
    }
  });



  useEffect(() => {
    function setInitPath() {
      if (
        initialPath === '/' &&
        [
          '/acceuil',
          '/aide-aux-victimes',
          '/signin',
          '/signup',
          '/confirm-signup',
          '/reset-password',
          '/error-pages/error-404',
          '/forget-password',
        ].indexOf(pathname) === -1
      ) {
        if (isPermitted) {
          dispatch(setInitialPath(pathname));
        } else {
          dispatch(setInitialPath(undefined));
        }
      }
    }

    setInitPath();
  }, [dispatch, isPermitted, initialPath, pathname]);

  useEffect(() => {
    function handleQueryParams() {
      const query = qs.parse(search.split('?')[1]);
      if (query.layout) {
        changeNavStyle(query.layout as NavStyle);
      }
      if (query.mode) {
        updateThemeMode(query.mode as ThemeMode);
      }
      if (query.rtl) {
        setRTL(true);
      }
      if (query.style) {
        updateThemeStyle!(query.style as ThemeStyle);
      }
    }

    if (search) {
      handleQueryParams();
    }
  }, [changeNavStyle, updateThemeMode, setRTL, updateThemeStyle, search]);

  useEffect(() => {
    if (!loading) {
      //console.log('AuthRoutes: isPermitted=' + isPermitted + ', initialPath=' + initialPath + ', pathname=' + pathname + ', user=', user, ',\n currentRoute=', currentRoute);
      if (pathname === initialUrl) { // Dynamic home page, based on authUser.role
        let homePageUrl = '/error-pages/error-404?role=unknown';
        if (!user) homePageUrl = '/acceuil';
        else if (checkPermission(authRole.anySecretariat, user.role)) {
          if(user.bureaux[0].nom === 'Paris Nord'){
            homePageUrl = '/secretariat/demande-de-visite';
          } else {
            homePageUrl = '/secretariat/rendez-vous/medecin';
          }
        }          
        else if (checkPermission(authRole.medecin, user.role)) {
          if(user.bureaux[0].nom === 'Paris Nord'){
            homePageUrl = '/examinateur/demande-de-visite';
          } else {
            homePageUrl = '/examinateur/rendez-vous/medecin';
          }
        }
        else if (checkPermission(authRole.psychologue, user.role)) homePageUrl = '/examinateur/rendez-vous/psychologue';
        history.push(homePageUrl);
      } else if (!user && !isPermitted) {
        history.push('/acceuil'); // allowed route
      } else if (user && !isPermitted) {
        history.push('/error-pages/error-404?url=' + pathname); // Not permitted
      } else if (user && isPermitted) {
        if (
          pathname === '/' ||
          pathname === '/acceuil' ||
          pathname === '/aide-aux-victimes' ||
          pathname === '/signin' ||
          pathname === '/signup'
        ) {
          history.push(initialUrl); // You are signed in, so leave the public pages and move inside
        } else {
          // @ts-ignore
          if (
            initialPath &&
            initialUrl !== initialPath &&
            initialPath !== '/'
            // initialPath !== '/signin' ||
            // initialPath !== '/signup')
          ) {
            history.push(initialPath);
          }
        }
      } else if (!user && isPermitted) { // Public page
        //console.log('!user && isPermitted');
      }
    }
  }, [user, loading, initialPath, isPermitted, pathname, history]);

  return loading ? <Loader /> : <>{children}</>;
};

export default AuthRoutes;
