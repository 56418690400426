import React from 'react';
import {Redirect} from 'react-router-dom';
import {authRole} from '../../shared/constants/AppConst';

export const adminConfig = [
  {
    auth: authRole.admin,
    routes: [
      {
        path: ['/admin/utilisateur/statut/:name', '/admin/utilisateur/role/:name'],
        component: React.lazy(() => import('./Utilisateur')),
      },
      {
        path: '/admin/utilisateur',
        component: () => <Redirect to='/admin/utilisateur/statut/tous' />,
      },
      {
        path: ['/admin/bureau/liste'],
        component: React.lazy(() => import('./Bureau')),
      },
      {
        path: '/admin/bureau',
        component: () => <Redirect to='/admin/bureau/liste' />,
      },
    ],
  },
];
