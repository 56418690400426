import {BureauObj} from '../models/admin/Bureau';

export const CREATE_NEW_BUREAU = 'CREATE_NEW_BUREAU';
export const DELETE_BUREAU = 'DELETE_BUREAU';
export const GET_BUREAU_LIST = 'GET_BUREAU_LIST';
export const UPDATE_BUREAU_DETAIL = 'UPDATE_BUREAU_DETAIL';

export interface GetObjetsActions {
  type: typeof GET_BUREAU_LIST;
  payload: {list: BureauObj[]; total: number};
}

export interface UpdateObjetActions {
  type: typeof UPDATE_BUREAU_DETAIL;
  payload: BureauObj;
}

export interface CreateObjetActions {
  type: typeof CREATE_NEW_BUREAU;
  payload: BureauObj;
}

export interface DeleteObjetActions {
  type: typeof DELETE_BUREAU;
  payload: {list: BureauObj[]; total: number};
}

export type BureauActions =
  | GetObjetsActions
  | UpdateObjetActions
  | CreateObjetActions
  | DeleteObjetActions;
