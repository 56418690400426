import {io, Socket} from 'socket.io-client';
import {apiConfig} from '../config';

const debug = false;
const verbose = false;

interface ServerToClientEvents {
  msgToClient: (data: string) => void;
  tokenInvalid: () => void;
}

interface ClientToServerEvents {}

interface ExtendedSocket
  extends Socket<ServerToClientEvents, ClientToServerEvents> {}

const socket: ExtendedSocket = io(apiConfig.socketURL, {
  autoConnect: false, // Connect when user signs in
});

export function connectToSocket(token: string) {
  if (socket.connected || !token) return;
  if (debug) console.log('Socket: connecting', socket);
  socket.auth = {token};
  socket.connect();
}

export function disconnectFromSocket() {
  if (debug) console.log('Socket: disconnecting', socket);
  socket.auth = {};
  socket.disconnect();
}

// Any event

socket.onAny((event, ...args) => {
  if (debug && verbose) console.log('Socket: any', event, args, socket); // catch-all listener
});

// Reserved events

socket.on('connect', () => {
  const engine = socket.io.engine;
  if (debug) console.log('Socket: connect', engine.transport.name, socket); // in most cases, "polling"

  engine.once('upgrade', (transport) => {
    // called when the transport is upgraded (i.e. from HTTP long-polling to WebSocket)
    if (debug) console.log('Socket: upgrade', transport, socket); // in most cases, "websocket"
  });

  engine.on('packet', (packet) => {
    if (debug && verbose) console.log('Socket: packet', packet, socket); // packet: {type, data} // called for each packet received
  });

  engine.on('packetCreate', (packet) => {
    if (debug && verbose) console.log('Socket: packetCreate', packet, socket); // packet: {type, data} // called for each packet sent
  });

  engine.on('drain', () => {
    if (debug && verbose) console.log('Socket: drain', socket); // called when the write buffer is drained
  });

  engine.on('close', (reason, desc) => {
    if (debug) console.log('Socket: close', reason, desc, socket); // called when the underlying connection is closed
  });
});

socket.on('connect_error', (err) => {
  if (debug) console.log('Socket: connect_error', err, socket);
  // try again
  setTimeout(() => {
    socket.connect();
  }, 8000);
});

socket.on('disconnect', (reason) => {
  if (debug) console.log('Socket: disconnect', reason, socket);
  if (reason === 'io server disconnect' && (socket.auth as any)?.token) {
    // the disconnection was initiated by the server, you need to reconnect manually
    setTimeout(() => {
      socket.connect();
    }, 8000);
  }
  // else the socket will automatically try to reconnect
});

// User events

type WebSocketNotificationCategories = 'ModificationTable';

type WebSocketNotificationsOperations = 'Insert' | 'Update' | 'Delete';

type WebSocketNotificationsTable = 'Configuration' | 'Bordereau' | 'RépartitionPaiement' | 'DossierPatient' | 'DisponibiliteDV' | 'DisponibiliteRV' | 'Commissariat' | 'Bureaux';

export interface WebSocketNotifications<T> {
  authSignature?: string;
  type: WebSocketNotificationCategories;

  // if (type === 'ModificationTable')
  table?: WebSocketNotificationsTable;
  operation?: WebSocketNotificationsOperations;
  payload?: Partial<T>;
}

socket.on('msgToClient', (data) => {
  if (debug) console.log('Socket: msgToClient', data, socket);
  const notification = data ? (JSON.parse(data) as WebSocketNotifications<any>) : undefined;
  if (!notification?.type) {
    console.log('Socket: msgToClient - ERROR: Invalid data', data, socket);
    return;
  }
  const fromMyself = !!notification.authSignature && ((socket.auth as any)?.token as string).endsWith(notification.authSignature);
  if (notification.type === 'ModificationTable') {
    if (!notification.table || !notification.operation)
      console.log('Socket: msgToClient - ERROR: Invalid ModificationTable', data, socket);
    else {
      document.dispatchEvent(new CustomEvent<WebSocketNotifications<any>>(notification.type, {detail: notification}));
      if (notification.table === 'Configuration' && !fromMyself)
        document.dispatchEvent(new CustomEvent('ModificationTableConfiguration'));
    }
    return;
  }
  console.log('Socket: msgToClient - ERROR: Unknown Type', data, socket);
});

socket.on('tokenInvalid', () => {
  if (debug) console.log('Socket: token_invalid', socket);
  disconnectFromSocket();
});
