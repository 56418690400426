import React, { useContext } from 'react';
import AppHeader from './AppHeader';
import AppSidebar from './AppSidebar';
import { ContentView, ThemeSetting } from '../../../index';
import Box from '@material-ui/core/Box';
import useStyles from './index.style';
import AppFixedFooter from './AppFixedFooter';
import AppContext from '../../../utility/AppContext';
import clsx from 'clsx';
import { LayoutType } from '../../../../shared/constants/AppEnums';
import AppContextPropsType from '../../../../types/AppContextPropsType';
import { fade } from '@material-ui/core';
import { getSelectionBureaux } from 'shared/components/AppLogo';

interface MiniSidebarToggleProps { }

const MiniSidebarToggle: React.FC<MiniSidebarToggleProps> = () => {
  const { footer, themeStyle, layoutType, footerType } = useContext<
    AppContextPropsType
  >(AppContext);
  const classes = useStyles({ footer, themeStyle });

  function getBackgroundColor() {
    const selection = getSelectionBureaux();
    if (selection.bureaux.length !== 1) return undefined;
    const b = selection.bureaux[0].nom;
    const color = b === 'Argenteuil' ? '#f00' : b === 'Chartres' ? '#0f0' : b === 'Paris Nord' ? '#00f' : '#fff'; // TODO: Better solution?
    return fade(color, 0.1); // === rgba(255, 0, 0, 0.1)
  }
  const backgroundColor = getBackgroundColor();

  return (
    <Box
      className={clsx(
        classes.appMain,
        layoutType === LayoutType.BOXED ? classes.boxedLayout : '',
        {
          appMainFooter: footer && footerType === 'fluid',
          appMainFixedFooter: footer && footerType === 'fixed',
        },
      )}>
      <AppSidebar />

      <Box className={clsx(classes.mainContent, 'main-content')}>
        <Box className={classes.mainContainerFull} style={{ backgroundColor }}>
          <AppHeader />
          <ContentView />
          <AppFixedFooter />
        </Box>
      </Box>
      <ThemeSetting />
    </Box>
  );
};

export default MiniSidebarToggle;
