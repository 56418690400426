import React from 'react';
import {Redirect} from 'react-router-dom';
import {authRole} from 'shared/constants/AppConst';

export const configurationConfig = [
  {
    auth: authRole.any,
    routes: [
      {
        path: ['/configuration/dprapport/liste'],
        component: React.lazy(() => import('./DPRapport/Liste')),
      },
      {
        path: '/configuration/dprapport',
        component: () => <Redirect to='/configuration/dprapport/liste' />,
      },
      {
        path: ['/configuration/dvzone/liste'],
        component: React.lazy(() => import('./DVZone/Liste')),
      },
      {
        path: '/configuration/dvzone',
        component: () => <Redirect to='/configuration/dvzone/liste' />,
      },
      {
        path: ['/configuration/dvcreneau/liste'],
        component: React.lazy(() => import('./DVCréneau/Liste')),
      },
      {
        path: '/configuration/dvcreneau',
        component: () => <Redirect to='/configuration/dvcreneau/liste' />,
      },
      {
        path: ['/configuration/typeexamen/liste'],
        component: React.lazy(() => import('./TypeExamen/Liste')),
      },
      {
        path: '/configuration/typeexamen',
        component: () => <Redirect to='/configuration/typeexamen/liste' />,
      },
      {
        path: ['/configuration/reglages'],
        component: React.lazy(() => import('./Réglages')),
      },
    ],
  },
];
