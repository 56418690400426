
export const apiConfig = {
  baseURL: process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : 'http://localhost:4200/v1/',
  baseStatsAndComptaURL: process.env.REACT_APP_API_STATS_AND_COMPTA_URL ? process.env.REACT_APP_API_STATS_AND_COMPTA_URL : 'http://localhost:4200/v1/',
  socketURL: process.env.REACT_APP_SOCKET_URL ? process.env.REACT_APP_SOCKET_URL : 'http://localhost:4200',
  debounceDelayInMilliseconds: process.env.DEBOUNCE_DELAY_IN_MILLIS && Number.isInteger(process.env.DEBOUNCE_DELAY_IN_MILLIS) ? parseInt(process.env.DEBOUNCE_DELAY_IN_MILLIS, 10) : 7000,
  retryAttempt: process.env.RETRY_ATTEMPT ? parseInt(process.env.RETRY_ATTEMPT, 10) : 40,
  retrySleepTimeout: process.env.RETRY_SLEEP_TIMEOUT ? parseInt(process.env.RETRY_SLEEP_TIMEOUT, 10) : 3 * 1000,
  retryFactorBackOff: process.env.RETRY_FACTOR_BACKOFF ? parseInt(process.env.RETRY_FACTOR_BACKOFF, 10) : 1.2,
  delayInSecondToRefreshManually: process.env.REFRESH_MANUALLY_AFTER ? parseInt(process.env.REFRESH_MANUALLY_AFTER, 10) : 3000
};

export const signinConfig = {
  initialEmail: '',
  initialPassword: '',
};
