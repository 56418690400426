import {UtilisateurObj} from '../models/admin/Utilisateur';

export const CREATE_NEW_UTILISATEUR = 'CREATE_NEW_UTILISATEUR';
export const DELETE_UTILISATEUR = 'DELETE_UTILISATEUR';
export const GET_UTILISATEUR_LIST = 'GET_UTILISATEUR_LIST';
export const UPDATE_UTILISATEUR_DETAIL = 'UPDATE_UTILISATEUR_DETAIL';

export interface GetObjetsActions {
  type: typeof GET_UTILISATEUR_LIST;
  payload: {list: UtilisateurObj[]; total: number};
}

export interface UpdateObjetActions {
  type: typeof UPDATE_UTILISATEUR_DETAIL;
  payload: UtilisateurObj;
}

export interface CreateObjetActions {
  type: typeof CREATE_NEW_UTILISATEUR;
  payload: UtilisateurObj;
}

export interface DeleteObjetActions {
  type: typeof DELETE_UTILISATEUR;
  payload: {list: UtilisateurObj[]; total: number};
}

export type UtilisateurActions =
  | GetObjetsActions
  | UpdateObjetActions
  | CreateObjetActions
  | DeleteObjetActions;
