import jwtAxios, {setAuthToken} from '@crema/services/auth/jwt-auth/jwt-api';
import {fetchError, fetchStart, fetchSuccess} from './Common';
import {authRole} from 'shared/constants/AppConst';
import {AuthUser} from 'types/models/AuthUser';
import {AppActions} from 'types';
import {Dispatch} from 'redux';
import {
  SET_AUTH_TOKEN,
  SIGNOUT_AUTH_SUCCESS,
  UPDATE_AUTH_USER,
} from 'types/actions/Auth.actions';
import {setSelectionBureauxInStorage} from 'shared/components/AppLogo';
import {UtilisateurObj} from 'types/models/admin/Utilisateur';
import {loadConfigurationList} from 'types/models/configuration/ConfigurationDefaultList';
import {getBureaux} from './BureauApp';

export const onJwtUserSignUp = (body: { // TODO: Has not been kept in sync with the API since users can't register themselves
  email: string;
  password: string;
  name: string;
}) => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const res = await jwtAxios.post('/auth/register', body);
      dispatch(setJWTToken(res.data.token));
      await loadJWTUser(dispatch);
    } catch (error: any) { // Error | AxiosError
      const message = typeof error === "string" ? error : (error?.response?.data?.message ?? error?.response?.data?.error ?? error?.message ?? 'Erreur inconnu') as string;
      console.log('onJwtUserSignUp', message, error); // TODO: Debug mode only!
      dispatch(fetchError(message));
    }
  };
};

export const onJwtSignIn = (body: {email: string; password: string}) => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const res = await jwtAxios.post('/auth/login', body);
      dispatch(setJWTToken(res.data.accessToken));
      await loadJWTUser(dispatch, true);
    } catch (error: any) { // Error | AxiosError
      const message = typeof error === "string" ? error : (error?.response?.data?.message ?? error?.response?.data?.error ?? error?.message ?? 'Erreur inconnu') as string;
      console.log('onJwtSignIn', message, error); // TODO: Debug mode only!
      dispatch(fetchError(message));
    }
  };
};

export const loadJWTUser = async (dispatch: Dispatch<AppActions>, isSignIn?: boolean) => {
  dispatch(fetchStart());
  try {
    await loadConfigurationList(await getBureaux());
    const res = await jwtAxios.get('/utilisateurs/moi');
    const authUser = getUserObject(res.data);
    if (isSignIn /*&& selection.bureaux.length === 0*/) setSelectionBureauxInStorage({
      bureaux: authUser.bureaux.length > 0 ? [authUser.bureaux[0]] : []
    });
    dispatch(fetchSuccess());
    dispatch({
      type: UPDATE_AUTH_USER,
      payload: authUser,
    });
  } catch (error: any) { // Error | AxiosError
    const message = typeof error === "string" ? error : (error?.response?.data?.message ?? error?.response?.data?.error ?? error?.message ?? 'Erreur inconnu') as string;
    console.log('loadJWTUser', message, error); // TODO: Debug mode only!
    dispatch(fetchError(message));
  }
};

export const setJWTToken = (token: string | null): AppActions => ({
  type: SET_AUTH_TOKEN,
  payload: token,
});

const getUserObject = (utilisateur: UtilisateurObj): AuthUser => {
  return {
    id: utilisateur.id,
    photoURL: '', // utilisateur.profilePictureUrl, // Note: /utilisateurs/moi doesn't return it. /users/me did => The avatar image in the navigation menu shows the first letter of the user's name
    displayName: utilisateur.nomComplet,
    email: utilisateur.adresseEmail,
    role: [utilisateur.role],
    isRoleAdministrateur: authRole.admin.includes(utilisateur.role),
    isRoleSecretariat: authRole.secretariat.includes(utilisateur.role),
    isRoleSecretariatExterne: authRole.secretariatExterne.includes(utilisateur.role),
    isRoleMedecin: authRole.medecin.includes(utilisateur.role),
    isRolePsychologue: authRole.psychologue.includes(utilisateur.role),
    isRoleExaminateur: authRole.anyExaminateur.includes(utilisateur.role),
    bureaux: utilisateur.bureaux,
  };
};

export const onJWTAuthSignout = () => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    setTimeout(() => {
      dispatch({type: SIGNOUT_AUTH_SUCCESS});
      dispatch(fetchSuccess());
      setAuthToken(null);
    }, 500);
  };
};
